import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Rating,
  TextField,
} from "@mui/material";
import { db } from "./firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import { useAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";

function ConnectionRequests() {
  const [requests, setRequests] = useState([]);
  const [connections, setConnections] = useState([]);
  const { currentUser } = useAuth();
  const [rating, setRating] = useState({});
  const [comment, setComment] = useState({});
  const [submittedRatings, setSubmittedRatings] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    // Real-time listener for connection requests
    const unsubscribeRequests = onSnapshot(
      collection(db, "users", currentUser.email, "connectionRequests"),
      (snapshot) => {
        const fetchedRequests = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRequests(fetchedRequests);
      }
    );

    // Real-time listener for accepted connections
    const unsubscribeConnections = onSnapshot(
      doc(db, "users", currentUser.email),
      async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const connectionsData = userData.connections || [];

          // Fetch ratings for each connection and prepopulate submittedRatings
          const ratingsMap = {};
          for (const connection of connectionsData) {
            const connectionDocRef = doc(db, "users", connection.email);
            const connectionDoc = await getDoc(connectionDocRef);
            if (connectionDoc.exists()) {
              const connectionData = connectionDoc.data();
              const rating = (connectionData.ratings || []).find(
                (r) => r.reviewer === currentUser.email
              );
              if (rating) {
                ratingsMap[connection.email] = {
                  rating: rating.rating,
                  comment: rating.comment,
                };
              }
            }
          }

          setConnections(connectionsData);
          setSubmittedRatings(ratingsMap); // Prepopulate submitted ratings
        }
      }
    );

    return () => {
      unsubscribeRequests();
      unsubscribeConnections();
    };
  }, [currentUser]);

  const handleAccept = async (request) => {
    try {
      // Fetch full details of the sender (User A) from flightDetails
      const senderFlightDetailsQuery = query(
        collection(db, "flightDetails"),
        where("email", "==", request.senderEmail)
      );
      const senderFlightDetailsSnapshot = await getDocs(
        senderFlightDetailsQuery
      );
      let senderFlightDetails = null;

      senderFlightDetailsSnapshot.forEach((doc) => {
        senderFlightDetails = doc.data(); // Assuming one match per email
      });
      let connectionDataForUserB;

      // Fetch full details of the current user (User B) from flightDetails
      const userFlightDetailsQuery = query(
        collection(db, "flightDetails"),
        where("email", "==", currentUser.email)
      );
      const userFlightDetailsSnapshot = await getDocs(userFlightDetailsQuery);
      let userFlightDetails = null;

      userFlightDetailsSnapshot.forEach((doc) => {
        userFlightDetails = doc.data(); // Assuming one match per email
      });

      if (!userFlightDetails) {
        throw new Error("Failed to fetch current user's flight details.");
      }


      // the user sending the request does not have any flight details filled out yet.
      //TODO: In future, add the option to store user profile details and fetch from the user profile
      // if the user does not have flight details filled out but want to send connection to a volunteer
      if (!senderFlightDetails) {
        connectionDataForUserB = {
          email: request.senderEmail,
          segments: userFlightDetails.segments || [],
        };
      } else {
        // Update connections for the current user (User B)
        connectionDataForUserB = {
          email: request.senderEmail,
          fullName: senderFlightDetails.fullName || "Unknown Name",
          segments: senderFlightDetails.segments || [],
          notes: senderFlightDetails.notes || "N/A",
          age: senderFlightDetails.age || "N/A",
          phoneNumber: senderFlightDetails.phoneNumber || "N/A",
        };
      }

      const userDocRef = doc(db, "users", currentUser.email);
      await updateDoc(userDocRef, {
        connections: arrayUnion(connectionDataForUserB),
      });

      // Update connections for the sender (User A)
      const connectionDataForUserA = {
        email: currentUser.email,
        fullName: userFlightDetails.fullName || "Unknown Name",
        segments: userFlightDetails.segments || [],
        notes: userFlightDetails.notes || "N/A",
        age: userFlightDetails.age || "N/A",
        phoneNumber: userFlightDetails.phoneNumber || "N/A",
      };

      const senderDocRef = doc(db, "users", request.senderEmail);
      await updateDoc(senderDocRef, {
        connections: arrayUnion(connectionDataForUserA),
      });

      // Remove the request from the connectionRequests collection
      const requestDocRef = doc(
        db,
        "users",
        currentUser.email,
        "connectionRequests",
        request.id
      );
      await deleteDoc(requestDocRef);

      alert("Connection request accepted.");
    } catch (error) {
      console.error("Error accepting connection request:", error);
      alert("Failed to accept connection request. Please try again.");
    }
  };

  const handleReject = async (requestId) => {
    try {
      const requestDocRef = doc(
        db,
        "users",
        currentUser.email,
        "connectionRequests",
        requestId
      );
      await deleteDoc(requestDocRef);

      alert("Connection request rejected.");
    } catch (error) {
      console.error("Error rejecting connection request:", error);
      alert("Failed to reject connection request. Please try again.");
    }
  };

  const handleRateConnection = async (
    connection,
    ratingValue,
    commentValue
  ) => {
    try {
      if (!ratingValue) {
        alert("Please provide a rating.");
        return;
      }

      const userDocRef = doc(db, "users", connection.email);

      // Ensure fields have fallback values
      const ratingData = {
        rating: ratingValue || 0,
        comment: commentValue || "No comment provided",
        reviewer: currentUser.email || "Anonymous",
      };

      await updateDoc(userDocRef, {
        ratings: arrayUnion(ratingData),
      });

      setSubmittedRatings((prev) => ({
        ...prev,
        [connection.email]: ratingData, // Update state immediately
      }));

      alert(`You rated ${connection.fullName} with ${ratingValue} stars.`);
    } catch (error) {
      console.error("Error rating connection:", error);
      alert("Failed to submit rating. Please try again.");
    }
  };

  return (
    <Box mt={4}>
      {/* Connection Requests Section */}
      <Box display="flex" flexDirection="column" alignItems="center" mb={6}>
        <Typography variant="h4" gutterBottom align="center">
          Connection Requests
        </Typography>
        {requests.length > 0 ? (
          <Grid container spacing={2} justifyContent="center">
            {requests.map((request) => (
              <Grid item xs={12} sm={8} md={6} key={request.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{request.senderName}</Typography>
                    <Typography variant="body2">
                      Age: {request.age || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      Notes: {request.notes || "N/A"}
                    </Typography>
                    {request.segments.map((segment, index) => (
                      <Box
                        key={index}
                        sx={{
                          backgroundColor: "#f5f5f5",
                          padding: "10px",
                          borderRadius: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Flight:</strong> {segment.flightNumber} (
                          {segment.airline})
                        </Typography>
                        <Typography variant="body2">
                          <strong>From:</strong> {segment.departure} (
                          {segment.departureDate} at {segment.departureTime})
                        </Typography>
                        <Typography variant="body2">
                          <strong>To:</strong> {segment.arrival} (
                          {segment.arrivalDate} at {segment.arrivalTime})
                        </Typography>
                      </Box>
                    ))}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAccept(request)}
                        sx={{ marginRight: 2 }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleReject(request.id)}
                      >
                        Reject
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            textAlign="center"
            mt={4}
            sx={{
              backgroundColor: "#f5f5f5",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: "#800040", fontWeight: "bold" }}
            >
              No Connection Requests
            </Typography>
            <Typography variant="body1" mt={2} sx={{ color: "#555" }}>
              Explore matches to find potential companions for your trip. Once
              you find someone, you can send them a connection request and start
              planning your journey together.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginTop: "16px",
                textTransform: "none",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0056b3" },
              }}
              onClick={() => navigate("/matches")}
            >
              Get Connected
            </Button>
          </Box>
        )}
      </Box>

      {/* Accepted Connections Section */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom align="center">
          Accepted Connections
        </Typography>
        {connections.length > 0 ? (
          <Grid container spacing={2} justifyContent="center">
            {connections.map((connection, index) => (
              <Grid item xs={12} sm={8} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{connection.email}</Typography>
                    <Typography
                      variant="body2"
                      style={{
                        marginTop: "20px",
                        color: "#25D366",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                        alt="WhatsApp"
                        style={{
                          width: "20px",
                          height: "20px",
                          verticalAlign: "middle",
                          marginRight: "5px",
                        }}
                      />
                      WhatsApp: {connection.phoneNumber || "N/A"}
                    </Typography>
                    {connection.segments.map((segment, segIndex) => (
                      <Box
                        key={segIndex}
                        sx={{
                          backgroundColor: "#f5f5f5",
                          padding: "10px",
                          borderRadius: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Flight:</strong> {segment.flightNumber} (
                          {segment.airline})
                        </Typography>
                        <Typography variant="body2">
                          <strong>From:</strong> {segment.departure} (
                          {segment.departureDate} at {segment.departureTime})
                        </Typography>
                        <Typography variant="body2">
                          <strong>To:</strong> {segment.arrival} (
                          {segment.arrivalDate} at {segment.arrivalTime})
                        </Typography>
                      </Box>
                    ))}
                    <Box mt={2}>
                      {submittedRatings[connection.email] ? (
                        <Box>
                          <Typography variant="body2">
                            <strong>Rating Submitted:</strong>
                          </Typography>
                          <Rating
                            value={submittedRatings[connection.email].rating}
                            readOnly
                          />
                          <Typography variant="body2">
                            <strong>Comment:</strong>{" "}
                            {submittedRatings[connection.email].comment ||
                              "No comment"}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          <Typography variant="body2">
                            Rate this user:
                          </Typography>
                          <Rating
                            value={rating[connection.email] || 0}
                            onChange={(e, newValue) =>
                              setRating({
                                ...rating,
                                [connection.email]: newValue,
                              })
                            }
                          />
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            placeholder="Add a comment (optional)"
                            value={comment[connection.email] || ""}
                            onChange={(e) =>
                              setComment({
                                ...comment,
                                [connection.email]: e.target.value,
                              })
                            }
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleRateConnection(
                                connection,
                                rating[connection.email],
                                comment[connection.email]
                              )
                            }
                            disabled={!rating[connection.email]}
                          >
                            Submit Rating
                          </Button>
                        </>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            textAlign="center"
            mt={4}
            mb={4}
            sx={{
              backgroundColor: "#f5f5f5",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: "#800040", fontWeight: "bold" }}
            >
              No Accepted Connections
            </Typography>
            <Typography variant="body1" mt={2} sx={{ color: "#555" }}>
              Accept connection requests or explore matches to connect with
              other travelers. Once connected, you can coordinate your plans and
              travel together for a more enjoyable experience.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ConnectionRequests;
