import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore"; // Firestore functions
import { useAuth } from "./context/AuthContext";

function Matches() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMatches = async () => {
      if (!currentUser) return;

      try {
        // Fetch the user's document from Firestore
        const userDocRef = doc(db, "users", currentUser.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.matches) {
            setMatches(userData.matches);
          } else {
            console.warn("No matches found in the user document.");
          }
        } else {
          console.warn("No user document found!");
        }
      } catch (error) {
        console.error("Error fetching matches:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, [currentUser]);

  const handleCardClick = (match) => {
    navigate(`/user-profile/${match.id}`, { state: { match } });
  };

  if (loading) {
    return <Typography>Loading matches...</Typography>;
  }

  return (
    <Box mt={4}>
      {matches.length > 0 ? (
        <>
          <Typography variant="h5" gutterBottom>
            ✈️ Your Matches
          </Typography>
          <Grid container spacing={3}>
            {matches.map((match, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  onClick={() => handleCardClick(match)}
                  style={{
                    cursor: "pointer",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {match.fullName}
                    </Typography>
                    <Divider />
                    <Box mt={2}>
                      <Typography variant="body2">
                        <strong>Age:</strong> {match.age || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>I am:</strong> {match.companionType}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Notes:</strong> {match.notes || "None"}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Box textAlign="center" mt={4}>
          <Card
            style={{
              padding: "24px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "12px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography variant="h5" gutterBottom>
              No Matches Found
            </Typography>
            <Divider style={{ margin: "16px 0" }} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="body1"
                mt={2}
                style={{ marginBottom: "16px" }}
              >
                Once your itinerary matches with someone, you will see the
                matches here, and you will receive an email about the match. You
                can connect with the match by sending a connection request.
                Click the button below if you have not submitted your flight
                details yet.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginBottom: "24px",
                  padding: "12px 24px",
                  fontSize: "1rem",
                }}
                onClick={() => navigate("/itinerary")}
              >
                Submit Your Itinerary Details
              </Button>
            </Box>
            <Divider style={{ margin: "16px 0" }} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="body1"
                style={{
                  marginBottom: "16px",
                  lineHeight: "1.6",
                }}
              >
                Haven't booked your flight yet? If you're looking for a
                companion, use this feature to explore volunteers who have
                already signed up to be a companion. Coordinate your plans and
                book your flight according to their itinerary for a more
                enjoyable travel experience.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  padding: "12px 24px",
                  fontSize: "1rem",
                }}
                onClick={() => navigate("/search-volunteers")}
              >
                Explore Volunteers
              </Button>
            </Box>
          </Card>
        </Box>
      )}
    </Box>
  );
}

export default Matches;
