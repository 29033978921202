import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import airlineOptions from "./airlineOptions";
import airportOptions from "./airportOptions";
import flightNumberPrefixes from "./flightNumberPrefixes";

const FlightSegmentsForm = ({
  flightSegments,
  handleSegmentChange,
  addSegment,
  removeSegment,
}) => {
  const validateFlightNumber = (airline, flightNumber) => {
    const prefix = flightNumberPrefixes[airline] || "";
    const regex = new RegExp(`^${prefix}[0-9]+$`); // Prefix followed by numbers only
    return flightNumber.length > prefix.length && regex.test(flightNumber);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Enter Your Flight Details (Including Layovers)
      </Typography>
      {flightSegments.map((segment, index) => (
        <Box
          key={index}
          mb={4}
          style={{
            border: "1px solid #ccc",
            padding: "16px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6">Flight {index + 1}</Typography>
          <Grid container spacing={2} key={index}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                options={airlineOptions}
                getOptionLabel={(option) => option.label || option}
                renderInput={(params) => (
                  <TextField {...params} label="Airline" required />
                )}
                value={segment.airline || ""}
                onChange={(event, value) => {
                  const selectedAirline = value?.label || value || "";

                  // Clear the flight number if the airline is cleared
                  handleSegmentChange(index, "airline", selectedAirline);
                  if (!selectedAirline) {
                    handleSegmentChange(index, "flightNumber", ""); // Clear flight number
                  } else {
                    // Auto-populate prefix if airline is valid
                    const prefix = flightNumberPrefixes[selectedAirline] || "";
                    handleSegmentChange(index, "flightNumber", prefix);
                  }
                }}
                onInputChange={(event, value) =>
                  handleSegmentChange(index, "airline", value || "")
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Flight Number"
                value={segment.flightNumber}
                onChange={(e) => {
                  let flightNumber = e.target.value;

                  // Remove invalid characters and enforce capitalization
                  flightNumber = flightNumber
                    .toUpperCase()
                    .replace(/[^A-Z0-9]/g, "");

                  // Ensure the prefix remains intact for valid airlines
                  const prefix = flightNumberPrefixes[segment.airline] || "";
                  if (prefix && !flightNumber.startsWith(prefix)) {
                    flightNumber = prefix + flightNumber.replace(/^[A-Z]*/, ""); // Replace any existing prefix
                  }

                  handleSegmentChange(index, "flightNumber", flightNumber);
                }}
                required
                fullWidth
                error={
                  segment.flightNumber &&
                  segment.airline &&
                  flightNumberPrefixes[segment.airline] && // Validate only for valid airlines
                  segment.flightNumber.length >
                    (flightNumberPrefixes[segment.airline] || "").length &&
                  !validateFlightNumber(segment.airline, segment.flightNumber)
                }
                helperText={
                  segment.flightNumber &&
                  segment.airline &&
                  flightNumberPrefixes[segment.airline] && // Provide helper text only for valid airlines
                  segment.flightNumber.length >
                    (flightNumberPrefixes[segment.airline] || "").length &&
                  !validateFlightNumber(segment.airline, segment.flightNumber)
                    ? `Flight number must start with ${
                        flightNumberPrefixes[segment.airline] || "XX"
                      } followed by numbers only.`
                    : segment.airline
                    ? flightNumberPrefixes[segment.airline]
                      ? `Enter the flight number. Example: ${flightNumberPrefixes[segment.airline]}123`
                      : "Enter flight number"
                    : "Select an airline to auto-populate the prefix"
                }
                placeholder={
                  segment.airline
                    ? `${flightNumberPrefixes[segment.airline] || ""}123`
                    : "Enter flight number"
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                options={airportOptions}
                getOptionLabel={(option) => option.label || option}
                renderInput={(params) => (
                  <TextField {...params} label="Departure Airport" required />
                )}
                value={segment.departure || ""}
                onChange={(event, value) =>
                  handleSegmentChange(
                    index,
                    "departure",
                    value?.label || value || ""
                  )
                }
                onInputChange={(event, value) =>
                  handleSegmentChange(index, "departure", value || "")
                }
                fullWidth
              />
            </Grid>

            {/* Departure Date */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Departure Date"
                type="date"
                value={segment.departureDate}
                onChange={(e) =>
                  handleSegmentChange(index, "departureDate", e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
              />
            </Grid>

            {/* Departure Time */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Departure Time"
                type="time"
                value={segment.departureTime}
                onChange={(e) =>
                  handleSegmentChange(index, "departureTime", e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
              />
            </Grid>

            {/* Arrival Airport */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                options={airportOptions}
                getOptionLabel={(option) => option.label || option}
                renderInput={(params) => (
                  <TextField {...params} label="Arrival Airport" required />
                )}
                value={segment.arrival || ""}
                onChange={(event, value) =>
                  handleSegmentChange(
                    index,
                    "arrival",
                    value?.label || value || ""
                  )
                }
                onInputChange={(event, value) =>
                  handleSegmentChange(index, "arrival", value || "")
                }
                fullWidth
              />
            </Grid>

            {/* Arrival Date */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Arrival Date"
                type="date"
                value={segment.arrivalDate}
                onChange={(e) =>
                  handleSegmentChange(index, "arrivalDate", e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
              />
            </Grid>

            {/* Arrival Time */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Arrival Time"
                type="time"
                value={segment.arrivalTime}
                onChange={(e) =>
                  handleSegmentChange(index, "arrivalTime", e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
              />
            </Grid>
          </Grid>

          {flightSegments.length > 1 && (
            <Button
              color="secondary"
              onClick={() => removeSegment(index)}
              style={{ marginTop: "16px" }}
            >
              Remove Flight
            </Button>
          )}
        </Box>
      ))}
      <Button
        onClick={addSegment}
        color="primary"
        variant="outlined"
        style={{ marginBottom: "16px" }}
      >
        Add Another Flight
      </Button>
    </Box>
  );
};

export default FlightSegmentsForm;
