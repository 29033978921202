import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Badge,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Icon for dropdown
import MenuIcon from "@mui/icons-material/Menu";

import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";

import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { db } from "./firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

function Header() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [matchesCount, setMatchesCount] = useState(0);
  const [requestsCount, setRequestsCount] = useState(0);
  const [acceptedConnectionsCount, setAcceptedConnectionsCount] = useState(0);
  const [couriersCount, setCouriersCount] = useState(0); // State for courier requests count
  const [courierMenuAnchor, setCourierMenuAnchor] = useState(null); // Anchor for the Couriers menu
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)"); // Medium screens

  useEffect(() => {
    if (!currentUser) return;

    // Listen for real-time updates to matches
    const matchesUnsubscribe = onSnapshot(
      doc(db, "users", currentUser.email),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const matches = userData.matches || [];
          setMatchesCount(matches.length);
        } else {
          setMatchesCount(0);
        }
      },
      (error) => {
        console.error("Error fetching matches updates:", error);
      }
    );

    // Listen for incoming connection requests
    const requestsUnsubscribe = onSnapshot(
      collection(db, "users", currentUser.email, "connectionRequests"),
      (snapshot) => {
        setRequestsCount(snapshot.size);
      },
      (error) => {
        console.error("Error fetching connection requests updates:", error);
      }
    );

    // Listen for accepted connections
    const acceptedConnectionsUnsubscribe = onSnapshot(
      doc(db, "users", currentUser.email),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();

          // Get the count of connections from the array
          const connectionsCount = (userData.connections || []).length;

          // Update the state with the count
          setAcceptedConnectionsCount(connectionsCount);
        }
      },
      (error) => {
        console.error("Error fetching accepted connections updates:", error);
      }
    );

    // Monitor outgoing requests to ensure the UI updates when a request is sent
    const outgoingRequestsUnsubscribe = onSnapshot(
      query(
        collection(db, "users"),
        where("connectionRequests", "array-contains", {
          senderEmail: currentUser.email,
        })
      ),
      (snapshot) => {
        setRequestsCount((prev) => prev + snapshot.size); // Increment based on outgoing requests
      },
      (error) => {
        console.error(
          "Error fetching outgoing connection requests updates:",
          error
        );
      }
    );

    // Listen for courier requests
    const couriersUnsubscribe = onSnapshot(
      collection(db, "users", currentUser.email, "courierRequests"),
      (snapshot) => {
        setCouriersCount(snapshot.size);
      },
      (error) => {
        console.error("Error fetching courier requests updates:", error);
      }
    );

    // Cleanup listeners on unmount
    return () => {
      matchesUnsubscribe();
      requestsUnsubscribe();
      couriersUnsubscribe();
      outgoingRequestsUnsubscribe();
      acceptedConnectionsUnsubscribe();
    };
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      setMatchesCount(0); // Clear matches count on logout
      setRequestsCount(0); // Clear requests count on logout
      setCouriersCount(0); // Clear couriers count on logout
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  const handleOpenCourierMenu = (event) => {
    setCourierMenuAnchor(event.currentTarget);
  };

  const handleCloseCourierMenu = () => {
    setCourierMenuAnchor(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const renderMenuItems = () => (
    <List style={{ width: 250, backgroundColor: "#f9f9f9" }}>
      {currentUser && (
        <ListItem
          button
          component={Link}
          to="/my-profile"
          onClick={toggleDrawer(false)}
          style={{
            padding: "12px 16px",
            marginBottom: 8,
            borderRadius: 4,
            transition: "background-color 0.3s ease",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#eee")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
        >
          <PersonIcon style={{ marginRight: 12, color: "#800040" }} />
          <ListItemText
            primary={`Welcome, ${currentUser.displayName || currentUser.email.split("@")[0]}`}
            primaryTypographyProps={{
              style: { fontSize: "16px", fontWeight: "bold", color: "#333" },
            }}
          />
        </ListItem>
      )}
      <ListItem
        button
        component={Link}
        to="/itinerary"
        onClick={toggleDrawer(false)}
        style={{
          padding: "12px 16px",
          marginBottom: 8,
          borderRadius: 4,
          transition: "background-color 0.3s ease",
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#eee")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
      >
        <HomeIcon style={{ marginRight: 12, color: "#800040" }} />
        <ListItemText
          primary="Home"
          primaryTypographyProps={{
            style: { fontSize: "16px", fontWeight: "500", color: "#333" },
          }}
        />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/matches"
        onClick={toggleDrawer(false)}
        style={{
          padding: "12px 16px",
          marginBottom: 8,
          borderRadius: 4,
          transition: "background-color 0.3s ease",
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#eee")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
      >
        <GroupIcon style={{ marginRight: 12, color: "#800040" }} />
        <Badge
          badgeContent={matchesCount}
          color="error"
          invisible={matchesCount === 0}
          style={{ marginRight: 12 }}
        >
          <ListItemText
            primary="Matches"
            primaryTypographyProps={{
              style: { fontSize: "16px", fontWeight: "500", color: "#333" },
            }}
          />
        </Badge>
      </ListItem>
      {currentUser && (
        <>
          <ListItem
            button
            component={Link}
            to="/connection-requests"
            onClick={toggleDrawer(false)}
            style={{
              padding: "12px 16px",
              marginBottom: 8,
              borderRadius: 4,
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#eee")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
          >
            <GroupIcon style={{ marginRight: 12, color: "#800040" }} />
            <Badge
              badgeContent={requestsCount + acceptedConnectionsCount}
              color="error"
              invisible={requestsCount + acceptedConnectionsCount === 0}
              style={{ marginRight: 12 }}
            >
              <ListItemText
                primary="Connection Requests"
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontWeight: "500", color: "#333" },
                }}
              />
            </Badge>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/search-couriers"
            onClick={toggleDrawer(false)}
            style={{
              padding: "12px 16px",
              marginBottom: 8,
              borderRadius: 4,
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#eee")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
          >
            <LocalShippingIcon style={{ marginRight: 12, color: "#800040" }} />
            <ListItemText
              primary="Search Couriers"
              primaryTypographyProps={{
                style: { fontSize: "16px", fontWeight: "500", color: "#333" },
              }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/courier-requests"
            onClick={toggleDrawer(false)}
            style={{
              padding: "12px 16px",
              marginBottom: 8,
              borderRadius: 4,
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#eee")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
          >
            <LocalShippingIcon style={{ marginRight: 12, color: "#800040" }} />
            <Badge
              badgeContent={couriersCount}
              color="error"
              invisible={couriersCount === 0}
              style={{ marginRight: 12 }}
            >
              <ListItemText
                primary="Courier Requests"
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontWeight: "500", color: "#333" },
                }}
              />
            </Badge>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleLogout();
              toggleDrawer(false)();
            }}
            style={{
              padding: "12px 16px",
              marginBottom: 8,
              borderRadius: 4,
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#eee")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
          >
            <LogoutIcon style={{ marginRight: 12, color: "#800040" }} />
            <ListItemText
              primary="Logout"
              primaryTypographyProps={{
                style: { fontSize: "16px", fontWeight: "bold", color: "#333" },
              }}
            />
          </ListItem>
        </>
      )}
      {!currentUser && (
        <ListItem
          button
          component={Link}
          to="/login"
          onClick={toggleDrawer(false)}
          style={{
            padding: "12px 16px",
            marginBottom: 8,
            borderRadius: 4,
            transition: "background-color 0.3s ease",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#eee")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
        >
          <LoginIcon style={{ marginRight: 12, color: "#800040" }} />
          <ListItemText
            primary="Login"
            primaryTypographyProps={{
              style: { fontSize: "16px", fontWeight: "bold", color: "#333" },
            }}
          />
        </ListItem>
      )}
    </List>
  );

  return (
    <AppBar position="sticky" style={{ backgroundColor: "#800040" }}>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: isSmallScreen ? "space-between" : "flex-start",
          alignItems: "center",
        }}
      >
        {isSmallScreen && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleDrawer(true)}
            style={{ marginRight: 16 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          style={{
            flexGrow: isSmallScreen ? 1 : 0,
            textAlign: isSmallScreen ? "center" : "left",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          Flight Friend
        </Typography>
        {!isSmallScreen && (
          <Box
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              flexWrap: isMediumScreen ? "wrap" : "nowrap", // Allow wrapping on medium screens
              justifyContent: isMediumScreen ? "center" : "flex-end", // Center on medium
              gap: isMediumScreen ? "12px" : "1px", // Adjust spacing for medium screens
            }}
          >
            <Button
              color="inherit"
              component={Link}
              to="/itinerary"
              style={{ textTransform: "none" }}
            >
              Home
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/matches"
              style={{ textTransform: "none", position: "relative" }}
            >
              <Badge
                badgeContent={matchesCount}
                color="error"
                invisible={matchesCount === 0}
              >
                Matches
              </Badge>
            </Button>
            {currentUser && currentUser.emailVerified ? (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/connection-requests"
                  style={{ textTransform: "none", position: "relative" }}
                >
                  <Badge
                    badgeContent={requestsCount + acceptedConnectionsCount}
                    color="error"
                    invisible={requestsCount + acceptedConnectionsCount === 0}
                  >
                    Connection Requests
                  </Badge>
                </Button>

                {/* Couriers Tab */}
                <IconButton
                  color="inherit"
                  style={{ textTransform: "none", position: "relative" }}
                  onMouseEnter={handleOpenCourierMenu}
                  onClick={handleOpenCourierMenu}
                >
                  <Badge
                    badgeContent={couriersCount}
                    color="error"
                    invisible={couriersCount === 0}
                  >
                    <Typography
                      variant="button"
                      color="inherit"
                      style={{ textTransform: "capitalize" }}
                    >
                      Couriers
                    </Typography>
                  </Badge>
                  <ArrowDropDownIcon />
                </IconButton>
                <Menu
                  anchorEl={courierMenuAnchor}
                  open={Boolean(courierMenuAnchor)}
                  onClose={handleCloseCourierMenu}
                  onMouseLeave={handleCloseCourierMenu}
                  MenuListProps={{
                    onMouseLeave: handleCloseCourierMenu,
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseCourierMenu();
                      navigate("/search-couriers");
                    }}
                  >
                    Search Couriers
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseCourierMenu();
                      navigate("/courier-requests");
                    }}
                  >
                    <Badge
                      badgeContent={couriersCount}
                      color="error"
                      invisible={couriersCount === 0}
                    >
                      Courier Requests
                    </Badge>
                  </MenuItem>
                </Menu>

                <Button
                  color="inherit"
                  onClick={() => navigate("/my-profile")}
                  style={{ textTransform: "none" }}
                >
                  Welcome, {currentUser.displayName || currentUser.email.split("@")[0]}
                </Button>
                <Button
                  color="inherit"
                  onClick={handleLogout}
                  style={{ textTransform: "none" }}
                >
                  Logout
                </Button>
              </>
            ) : (
              <Button
                color="inherit"
                component={Link}
                to="/login"
                style={{ textTransform: "none" }}
              >
                Login
              </Button>
            )}
          </Box>
        )}
      </Toolbar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {renderMenuItems()}
      </Drawer>
    </AppBar>
  );
}

export default Header;
