import React from "react";
import { Grid, TextField, Typography, Checkbox, FormControlLabel } from "@mui/material";

const PassengerDetailsForm = ({
  flightDetails,
  handleChange,
  selectedNotes,
  handleNotesChange,
  noteOptions,
  errors
}) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Passenger Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Full Name"
            name="fullName"
            value={flightDetails.fullName}
            onChange={handleChange}
            required
            fullWidth
            error={!!errors.fullName}
            helperText={errors.fullName || "Enter your full name"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={flightDetails.phoneNumber}
            onChange={handleChange}
            required
            fullWidth
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Age"
            name="age"
            type="number"
            value={flightDetails.age}
            onChange={handleChange}
            required
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom mt={3}>
        Notes
      </Typography>
      <Grid container spacing={2}>
        {noteOptions.map((option) => (
          <Grid item xs={12} sm={6} key={option}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedNotes.includes(option)}
                  onChange={() => handleNotesChange(option)}
                />
              }
              label={option}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <TextField
            label="Additional Notes"
            helperText="Click on the checkboxes above or type any other notes/preferences"
            name="notes"
            value={flightDetails.notes}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PassengerDetailsForm;
