import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useAuth } from "./context/AuthContext";

const ManageVolunteerDetails = () => {
  const { currentUser } = useAuth();
  const [volunteerDetails, setVolunteerDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const fetchVolunteerDetails = async () => {
    setLoading(true);
    try {
      const q = query(
        collection(db, "flightDetails"),
        where("email", "==", currentUser.email),
        where("companionType", "==", "Volunteering to be a companion")
      );
      const querySnapshot = await getDocs(q);

      const fetchedDetails = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const segments = data.segments || []; // Ensure segments exist
        fetchedDetails.push({
          id: doc.id,
          fullName: data.fullName || "Unknown",
          segments: segments, // Store all segments for display
          notes: data.notes || "None",
        });
      });
      setVolunteerDetails(fetchedDetails);
    } catch (err) {
      console.error("Error fetching volunteer details:", err);
      setError("Failed to fetch details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const docRef = doc(db, "flightDetails", id);
      await deleteDoc(docRef);
      setSuccessMessage("Volunteer details deleted successfully.");
      fetchVolunteerDetails(); // Refresh data
    } catch (err) {
      console.error("Error deleting details:", err);
      setError("Failed to delete details. Please try again.");
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchVolunteerDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <Box
      mt={4}
      p={4}
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        background: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Manage My Volunteer Details
      </Typography>

      {error && (
        <Alert severity="error" style={{ marginBottom: "16px" }}>
          {error}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" style={{ marginBottom: "16px" }}>
          {successMessage}
        </Alert>
      )}

      {loading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}

      {!loading && volunteerDetails.length === 0 && (
        <Typography>No volunteer details found.</Typography>
      )}

      {volunteerDetails.length > 0 && (
        <Grid container spacing={2}>
          {volunteerDetails.map((detail) => (
            <Grid item xs={12} sm={6} key={detail.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{detail.fullName}</Typography>

                  {/* Iterate over segments to display flight details */}
                  {detail.segments.map((segment, index) => (
                    <Box
                      key={index}
                      style={{
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        borderRadius: "5px",
                        marginTop: "10px",
                      }}
                    >
                      <Typography variant="body2">
                        <strong>Flight Number:</strong>{" "}
                        {segment.flightNumber || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Airline:</strong> {segment.airline || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Departure:</strong> {segment.departure} (
                        {segment.departureDate} at {segment.departureTime})
                      </Typography>
                      <Typography variant="body2">
                        <strong>Arrival:</strong> {segment.arrival} (
                        {segment.arrivalDate} at {segment.arrivalTime})
                      </Typography>
                    </Box>
                  ))}

                  <Typography variant="body2">
                    <strong>Notes:</strong> {detail.notes}
                  </Typography>

                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDelete(detail.id)}
                    >
                      Delete
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ManageVolunteerDetails;
