import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Rating,
  TextField,
  Divider,
} from "@mui/material";
import {
  Phone,
  FlightTakeoff,
  FlightLand,
  Person,
  Comment,
  CheckCircle,
  SwapHoriz,
  InfoOutlined,
} from "@mui/icons-material";
import { db } from "./firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
  onSnapshot,
  arrayRemove,
} from "firebase/firestore";
import { useAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";


function CourierRequests() {
  const [requests, setRequests] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const { currentUser } = useAuth();
  const [rating, setRating] = useState({});
  const [comment, setComment] = useState({});
  const [submittedRatings, setSubmittedRatings] = useState({});
  const [phoneNumbers, setPhoneNumbers] = useState({});
  const [exchangeStatus, setExchangeStatus] = useState({}); // Tracks if contacts have been exchanged
  const navigate = useNavigate();


  useEffect(() => {
    if (!currentUser) return;

    // Real-time listener for courier requests
    const unsubscribeRequests = onSnapshot(
      collection(db, "users", currentUser.email, "courierRequests"),
      (snapshot) => {
        const fetchedRequests = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRequests(fetchedRequests);
      }
    );

    // Real-time listener for accepted couriers
    const unsubscribeCouriers = onSnapshot(
      doc(db, "users", currentUser.email),
      async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const couriersData = userData.couriers || [];

          // Fetch ratings for each courier and prepopulate submittedRatings
          const ratingsMap = {};
          for (const courier of couriersData) {
            const courierDocRef = doc(db, "users", courier.email);
            const courierDoc = await getDoc(courierDocRef);
            if (courierDoc.exists()) {
              const courierData = courierDoc.data();
              const rating = (courierData.ratings || []).find(
                (r) => r.reviewer === currentUser.email
              );
              if (rating) {
                ratingsMap[courier.email] = {
                  rating: rating.rating,
                  comment: rating.comment,
                };
              }
            }
          }

          setCouriers(couriersData);
          // Update exchange status
          const status = {};
          couriersData.forEach((courier) => {
            if (courier.phoneNumber && courier.receiverPhoneNumber) {
              status[courier.email] = true; // Both exchanged
            }
          });
          setExchangeStatus(status);

          setSubmittedRatings(ratingsMap); // Prepopulate submitted ratings
        }
      }
    );

    return () => {
      unsubscribeRequests();
      unsubscribeCouriers();
    };
  }, [currentUser]);

  const handleAccept = async (request) => {
    try {
      const senderEmail = request.senderEmail;
      const currentUserDocRef = doc(db, "users", currentUser.email);
      const senderDocRef = doc(db, "users", senderEmail);
  
      // Fetch current user's flight details (receiver's data)
      const userFlightDetailsQuery = query(
        collection(db, "flightDetails"),
        where("email", "==", currentUser.email)
      );
      const userFlightDetailsSnapshot = await getDocs(userFlightDetailsQuery);
      let userFlightDetails = {};
      userFlightDetailsSnapshot.forEach((doc) => {
        userFlightDetails = doc.data();
      });
  
      // Ensure receiver's flight details are available
      if (!userFlightDetails.fullName) {
        throw new Error("Missing flight details for the accepting user.");
      }
  
      // Prepare courier data for the current user (receiver)
      const courierDataForReceiver = {
        email: senderEmail,
        fullName: request.senderName || "Unknown Name", // Use sender's name from the request
        segments: request.segments || [],
        notes: request.notes || "N/A",
        courierNote: request.courierNote || "No note provided",
        age: request.age || "N/A",
      };
  
      // Prepare courier data for the sender
      const courierDataForSender = {
        email: currentUser.email,
        fullName: userFlightDetails.fullName || "Unknown Name",
        segments: userFlightDetails.segments || [],
        notes: "Request Accepted",
        courierNote: request.courierNote || "No note provided",
        age: userFlightDetails.age || "N/A",
      };
  
      // Check if sender exists
      const senderDoc = await getDoc(senderDocRef);
  
      if (senderDoc.exists()) {
        // Update sender's couriers if they exist
        await updateDoc(senderDocRef, {
          couriers: arrayUnion(courierDataForSender),
        });
      } else {
        // Create a new document for the sender
        await setDoc(senderDocRef, {
          email: senderEmail,
          fullName: request.senderName || "Unknown Name",
          couriers: [courierDataForSender], // Initialize couriers array
          ratings: [], // Optional: Initialize other fields
          createdAt: new Date(),
        });
      }
  
      // Update couriers for the current user (receiver)
      await updateDoc(currentUserDocRef, {
        couriers: arrayUnion(courierDataForReceiver),
      });
  
      // Remove the request from the courierRequests collection
      const requestDocRef = doc(
        db,
        "users",
        currentUser.email,
        "courierRequests",
        request.id
      );
      await deleteDoc(requestDocRef);
  
      alert("Courier request accepted successfully!");
    } catch (error) {
      console.error("Error accepting courier request:", error);
      alert("Failed to accept courier request. Please try again.");
    }
  };
  

  const handleReject = async (requestId) => {
    try {
      const requestDocRef = doc(
        db,
        "users",
        currentUser.email,
        "courierRequests",
        requestId
      );
      await deleteDoc(requestDocRef);

      alert("Courier request rejected.");
    } catch (error) {
      console.error("Error rejecting courier request:", error);
      alert("Failed to reject courier request. Please try again.");
    }
  };

  const handleRateCourier = async (courier, ratingValue, commentValue) => {
    try {
      if (!ratingValue) {
        alert("Please provide a rating.");
        return;
      }

      const userDocRef = doc(db, "users", courier.email);

      const ratingData = {
        rating: ratingValue || 0,
        comment: commentValue || "No comment provided",
        reviewer: currentUser.email || "Anonymous",
      };

      await updateDoc(userDocRef, {
        ratings: arrayUnion(ratingData),
      });

      setSubmittedRatings((prev) => ({
        ...prev,
        [courier.email]: ratingData, // Update state immediately
      }));

      alert(`You rated ${courier.fullName} with ${ratingValue} stars.`);
    } catch (error) {
      console.error("Error rating courier:", error);
      alert("Failed to submit rating. Please try again.");
    }
  };

  const handleExchangeContact = async (courier) => {
    const phoneNumber = phoneNumbers[courier.email];
    const phoneRegex = /^[+]?[1-9]\d{1,14}$/; // E.164 format: + followed by 10-15 digits

    if (!phoneNumber || !phoneRegex.test(phoneNumber.trim())) {
      alert("Please enter a valid phone number.");
      return;
    }

    try {
      const currentUserDocRef = doc(db, "users", currentUser.email);
      const otherUserDocRef = doc(db, "users", courier.email);

      // Update current user's couriers array with own phone number
      const updatedCourierData = {
        ...courier,
        receiverPhoneNumber: phoneNumber,
      };
      await updateDoc(currentUserDocRef, {
        couriers: arrayRemove(courier), // Remove old data
      });
      await updateDoc(currentUserDocRef, {
        couriers: arrayUnion(updatedCourierData), // Add updated data
      });

      // Update the other user's couriers array with current user's phone number
      const otherUserSnapshot = await getDoc(otherUserDocRef);
      if (otherUserSnapshot.exists()) {
        const otherUserData = otherUserSnapshot.data();
        const matchingCourier = otherUserData.couriers.find(
          (c) => c.email === currentUser.email
        );

        if (matchingCourier) {
          const updatedOtherCourierData = {
            ...matchingCourier,
            phoneNumber, // Share phone number with the other user
          };
          await updateDoc(otherUserDocRef, {
            couriers: arrayRemove(matchingCourier), // Remove old data
          });
          await updateDoc(otherUserDocRef, {
            couriers: arrayUnion(updatedOtherCourierData), // Add updated data
          });
        }
      }

      setExchangeStatus((prev) => ({
        ...prev,
        [courier.email]: true, // Mark exchange as complete
      }));
      alert("Contact information exchanged successfully!");
    } catch (error) {
      console.error("Error exchanging contact information:", error);
      alert("Failed to exchange contact information. Please try again.");
    }
  };

  return (
    <Box mt={4}>
      {/* Courier Requests Section */}
      <Typography variant="h4" gutterBottom align="center">
        Courier Requests
      </Typography>
      {requests.length > 0 ? (
        <Grid container spacing={3} justifyContent="center">
          {requests.map((request) => (
            <Grid item xs={12} sm={6} md={4} key={request.id}>
              <Card
                sx={{
                  borderRadius: "12px",
                  boxShadow: 3,
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: "scale(1.02)" },
                }}
              >
                <CardContent>
                  <Typography variant="h6">{request.senderName}</Typography>
                  <Typography variant="body2">
                    Age: {request.age || "N/A"}
                  </Typography>
                  {/* Display the courierNote here */}
                  {request.courierNote && (
                    <Box mt={2}>
                      <Typography
                        variant="body2"
                        sx={{ fontStyle: "italic", color: "text.secondary" }}
                      >
                        <strong>Message:</strong> {request.courierNote}
                      </Typography>
                    </Box>
                  )}
                  <Typography variant="body2">
                    Notes: {request.notes || "N/A"}
                  </Typography>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAccept(request)}
                      sx={{ marginRight: 1 }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleReject(request.id)}
                    >
                      Reject
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          textAlign="center"
          mt={4}
          p={4}
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#800040", fontWeight: "bold", marginBottom: "16px" }}
          >
            No Courier Requests at the Moment
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#555", lineHeight: "1.6", marginBottom: "12px" }}
          >
            You haven't received any courier requests yet. Make sure your flight
            details are up-to-date and that you've enabled the "Open to Courier
            Requests" option in your itinerary.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#0056b3" },
            }}
            onClick={() => navigate("/itinerary")}
          >
            Update Your Itinerary
          </Button>
        </Box>
      )}

      {/* Accepted Couriers Section */}
      <Box mt={6}>
        <Typography variant="h4" gutterBottom align="center">
          Accepted Couriers
        </Typography>
        {couriers.length > 0 ? (
          <Grid container spacing={4} justifyContent="center">
            {couriers.map((courier, index) => (
              <Grid item xs={12} sm={8} md={6} key={index}>
                <Card
                  sx={{
                    borderRadius: "12px",
                    boxShadow: 3,
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": { transform: "scale(1.02)" },
                  }}
                >
                  <CardContent>
                    {/* Header Section */}
                    <Box display="flex" alignItems="center" mb={2}>
                      <Person color="primary" sx={{ marginRight: 1 }} />
                      <Typography variant="h6" fontWeight="bold">
                        {courier.fullName || courier.email}
                      </Typography>
                    </Box>

                    <Divider />
                    {/* Courier Note Section */}
                    {courier.courierNote && (
                      <Box mt={2}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          mb={1}
                        >
                          <InfoOutlined
                            fontSize="small"
                            sx={{ marginRight: 1 }}
                          />
                          <strong>Courier Note:</strong> {courier.courierNote}
                        </Typography>
                      </Box>
                    )}

                    <Divider />

                    {/* Contact Exchange Section */}
                    <Box mt={2}>
                      <Typography variant="body2" color="textSecondary" mb={1}>
                        <Phone fontSize="small" /> <strong>Your Phone:</strong>{" "}
                        {courier.receiverPhoneNumber || "Not shared yet"}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <Phone fontSize="small" /> <strong>Their Phone:</strong>{" "}
                        {courier.phoneNumber || "Not shared yet"}
                      </Typography>

                      <Box mt={2}>
                        {!exchangeStatus[courier.email] ? (
                          <>
                            <TextField
                              label="Your Phone Number"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={phoneNumbers[courier.email] || ""}
                              onChange={(e) =>
                                setPhoneNumbers({
                                  ...phoneNumbers,
                                  [courier.email]: e.target.value,
                                })
                              }
                              sx={{ marginBottom: "10px" }}
                            />
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              startIcon={<SwapHoriz />}
                              onClick={() => handleExchangeContact(courier)}
                            >
                              Exchange Contact
                            </Button>
                          </>
                        ) : (
                          <Typography
                            color="success"
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            <CheckCircle
                              color="success"
                              sx={{ marginRight: 1 }}
                            />{" "}
                            Contact exchange completed.
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    <Divider />

                    {/* Flight Details Section */}
                    <Box mt={2}>
                      {courier.segments.map((segment, segIndex) => (
                        <Box
                          key={segIndex}
                          sx={{
                            backgroundColor: "#f0f4ff",
                            padding: "8px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <Typography variant="body2">
                            <FlightTakeoff fontSize="small" />{" "}
                            <strong>Flight:</strong> {segment.flightNumber} (
                            {segment.airline})
                          </Typography>
                          <Typography variant="body2">
                            <FlightTakeoff fontSize="small" />{" "}
                            <strong>From:</strong> {segment.departure} (
                            {segment.departureDate} at {segment.departureTime})
                          </Typography>
                          <Typography variant="body2">
                            <FlightLand fontSize="small" /> <strong>To:</strong>{" "}
                            {segment.arrival} ({segment.arrivalDate} at{" "}
                            {segment.arrivalTime})
                          </Typography>
                        </Box>
                      ))}
                    </Box>

                    <Divider />

                    {/* Rating Section */}
                    <Box mt={2}>
                      {submittedRatings[courier.email] ? (
                        <Box>
                          <Typography variant="body2">
                            <Comment fontSize="small" />{" "}
                            <strong>Rating Submitted:</strong>
                          </Typography>
                          <Rating
                            value={submittedRatings[courier.email].rating}
                            readOnly
                          />
                          <Typography variant="body2">
                            <strong>Comment:</strong>{" "}
                            {submittedRatings[courier.email].comment ||
                              "No comment"}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          <Typography variant="body2">
                            <strong>Rate this courier:</strong>
                          </Typography>
                          <Rating
                            value={rating[courier.email] || 0}
                            onChange={(e, newValue) =>
                              setRating({
                                ...rating,
                                [courier.email]: newValue,
                              })
                            }
                          />
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            placeholder="Add a comment (optional)"
                            value={comment[courier.email] || ""}
                            onChange={(e) =>
                              setComment({
                                ...comment,
                                [courier.email]: e.target.value,
                              })
                            }
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleRateCourier(
                                courier,
                                rating[courier.email],
                                comment[courier.email]
                              )
                            }
                            disabled={!rating[courier.email]}
                            fullWidth
                          >
                            Submit Rating
                          </Button>
                        </>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) :  (
          <Box
            textAlign="center"
            mt={4}
            mb={4}
            p={4}
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: "#800040", fontWeight: "bold", marginBottom: "16px" }}
            >
              No Accepted Couriers Yet
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#555", lineHeight: "1.6", marginBottom: "12px" }}
            >
              You haven’t accepted any courier requests yet. Explore incoming courier
              requests to help others with their urgent delivery needs and build
              meaningful connections during your travels.
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#666", marginBottom: "16px" }}
            >
              Don’t see any requests? Ensure your itinerary is set to "Open to Courier
              Requests" and keep an eye out for new opportunities.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default CourierRequests;
