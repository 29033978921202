import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Alert,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import { db } from "./firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";
import SubmittedFlights from "./SubmittedFlights";
import HaventBookedFlight from "./HaventBookedFlight";
import AcceptedConnections from "./AcceptedConnections";
import FlightSegmentsForm from "./FlightSegmentsForm";
import PassengerDetailsForm from "./PassengerDetailsForm";
import flightNumberPrefixes from "./flightNumberPrefixes";

const FlightForm = ({ setMatches }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  // Multi-leg flights state
  const [flightSegments, setFlightSegments] = useState([
    {
      flightNumber: "",
      airline: "",
      departure: "",
      departureDate: "",
      departureTime: "",
      arrival: "",
      arrivalDate: "",
      arrivalTime: "",
    },
  ]);


  const [flightDetails, setFlightDetails] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    age: "",
    notes: "",
    companionType: "",
    canCarryItems: false
  });

  const [selectedNotes, setSelectedNotes] = useState([]);
  const noteOptions = [
    "Can't speak English",
    "Senior citizen",
    "Parent travelling with a kid",
    "Wheelchair",
    "Long layover",
  ];

  const [connections, setConnections] = useState([]); // State for accepted connections
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [submittedFlights, setSubmittedFlights] = useState([]); // Track submitted flights
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (currentUser) {
      setFlightDetails((prevDetails) => ({
        ...prevDetails,
        email: currentUser.email,
        fullName: currentUser.displayName || currentUser.email.split("@")[0],
      }));

      // Fetch accepted connections and submitted flights
      fetchConnections();
      fetchSubmittedFlights();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const fetchConnections = async () => {
    try {
      const userDocRef = doc(db, "users", currentUser.email);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setConnections(userData.connections || []);
      }
    } catch (error) {
      console.error("Error fetching connections:", error);
    }
  };

  const fetchSubmittedFlights = async () => {
    try {
      const q = query(
        collection(db, "flightDetails"),
        where("email", "==", currentUser.email)
      );
      const querySnapshot = await getDocs(q);
      const flights = [];
      querySnapshot.forEach((doc) => {
        flights.push({ id: doc.id, ...doc.data() });
      });
      setSubmittedFlights(flights); // Update submitted flights
    } catch (error) {
      console.error("Error fetching submitted flights:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFlightDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Validation logic
    if (name === "fullName") {
      const nameRegex = /^[a-zA-Z\s]+$/; // Allows only letters and spaces
      if (!nameRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fullName: "Full Name must not contain numbers or special characters.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fullName: "",
        }));
      }
    }

    // Add phone number validation
    if (name === "phoneNumber") {
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;

      if (!phoneRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber:
            "Invalid phone number. Use national or international format.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "",
        }));
      }
    }
  };

  const handleNotesChange = (option) => {
    const updatedNotes = selectedNotes.includes(option)
      ? selectedNotes.filter((note) => note !== option)
      : [...selectedNotes, option];
    setSelectedNotes(updatedNotes);
    setFlightDetails({ ...flightDetails, notes: updatedNotes.join(", ") });
  };

  const validateFlightNumber = (airline, flightNumber) => {
    const prefix = flightNumberPrefixes[airline] || "";
    const regex = new RegExp(`^${prefix}[0-9]+$`); // Prefix followed by numbers only
    return regex.test(flightNumber);
  };

  // eslint-disable-next-line no-unused-vars
  const normalizeData = (details) => ({
    flightNumber: details.flightNumber.toLowerCase(),
    airline: details.airline.toLowerCase(),
    departure: details.departure.toLowerCase(),
    departureDate: details.departureDate,
    departureTime: details.departureTime,
    arrival: details.arrival.toLowerCase(),
    arrivalDate: details.arrivalDate,
    arrivalTime: details.arrivalTime,
  });

  const handleSegmentChange = (index, field, value) => {
    const updatedSegments = [...flightSegments];
    updatedSegments[index][field] = value;
    setFlightSegments(updatedSegments);
  };

  const addSegment = () => {
    setFlightSegments([
      ...flightSegments,
      {
        flightNumber: "",
        airline: "",
        departure: "",
        departureDate: "",
        departureTime: "",
        arrival: "",
        arrivalDate: "",
        arrivalTime: ""
      },
    ]);
  };

  const removeSegment = (index) => {
    const updatedSegments = flightSegments.filter((_, i) => i !== index);
    setFlightSegments(updatedSegments);
  };

  const normalizeField = (value) => (value ? value.toLowerCase().trim() : "");

  const normalizeSegments = (segments) => {
    return segments.map((segment) => ({
      flightNumber: normalizeField(segment.flightNumber),
      airline: normalizeField(segment.airline),
      departure: normalizeField(segment.departure),
      departureDate: normalizeField(segment.departureDate),
      departureTime: normalizeField(segment.departureTime),
      arrival: normalizeField(segment.arrival),
      arrivalDate: normalizeField(segment.arrivalDate),
      arrivalTime: normalizeField(segment.arrivalTime),
    }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for errors before submission
    if (errors.phoneNumber || errors.fullName) {
      setErrorMessage("Please fix the errors in the form before submitting.");
      return;
    }

    // Validate flight segments
    const invalidSegments = flightSegments.filter(
      (segment) =>
        !validateFlightNumber(segment.airline, segment.flightNumber) &&
        segment.flightNumber // Ensure flightNumber is not empty
    );

    if (invalidSegments.length > 0) {
      setErrorMessage(
        `Invalid flight number detected for airline: ${
          invalidSegments[0].airline
        }. Flight numbers should start with ${
          flightNumberPrefixes[invalidSegments[0].airline] || "XX"
        }.`
      );
      return;
    }

    if (
      flightSegments.some(
        (segment) =>
          !segment.flightNumber ||
          !segment.airline ||
          !segment.departure ||
          !segment.departureDate ||
          !segment.departureTime ||
          !segment.arrival ||
          !segment.arrivalDate ||
          !segment.arrivalTime
      )
    ) {
      setErrorMessage("Please complete all flight segment details.");
      return;
    }


    if (
      !flightDetails.fullName ||
      !flightDetails.phoneNumber ||
      !flightDetails.age ||
      !flightDetails.companionType
    ) {
      setErrorMessage("Please complete all passenger details.");
      return;
    }

    setLoadingMatches(true);

    try {
      const normalizedSegments = normalizeSegments(flightSegments);
      // eslint-disable-next-line no-unused-vars
      const normalizedDetails = {
        ...flightDetails,
        segments: normalizedSegments,
      };

      // Check for duplicate submissions
      const existingQuery = query(
        collection(db, "flightDetails"),
        where("email", "==", currentUser.email)
      );
      const existingSnapshot = await getDocs(existingQuery);

      let isDuplicate = false;

      existingSnapshot.forEach((doc) => {
        const data = doc.data();
        const existingSegments = normalizeSegments(data.segments || []);
        if (
          JSON.stringify(existingSegments) ===
          JSON.stringify(normalizedSegments)
        ) {
          isDuplicate = true;
        }
      });

      if (isDuplicate) {
        setErrorMessage("You have already submitted these flight details.");
        setLoadingMatches(false);
        return;
      }

      // Add new flight details
      const docRef = await addDoc(collection(db, "flightDetails"), {
        ...flightDetails,
        segments: flightSegments,
      });

      // Matching Logic
      const matchQuery = query(collection(db, "flightDetails"));
      const matchSnapshot = await getDocs(matchQuery);

      const matchedItineraries = [];
      matchSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.email === currentUser.email) return; // Exclude current user's flights

        const existingSegments = normalizeSegments(data.segments || []);
        if (
          existingSegments.length === normalizedSegments.length &&
          existingSegments.every((segment, index) => {
            const submittedSegment = normalizedSegments[index];
            return (
              segment.flightNumber === submittedSegment.flightNumber &&
              segment.airline === submittedSegment.airline &&
              segment.departureDate === submittedSegment.departureDate &&
              segment.departureTime === submittedSegment.departureTime &&
              segment.arrivalDate === submittedSegment.arrivalDate &&
              segment.arrivalTime === submittedSegment.arrivalTime
            );
          })
        ) {
          matchedItineraries.push({ id: doc.id, ...data });
        }
      });

      setMatches(matchedItineraries);

      // Save matches to the user profile
      const userDocRef = doc(db, "users", flightDetails.email);
      await setDoc(
        userDocRef,
        { matches: arrayUnion(...matchedItineraries) },
        { merge: true }
      );

      // Update matches for matched users
      for (const match of matchedItineraries) {
        const matchedUserDocRef = doc(db, "users", match.email);
        const matchedUserDoc = await getDoc(matchedUserDocRef);

        if (matchedUserDoc.exists()) {
          const matchedUserData = matchedUserDoc.data();
          const existingMatches = matchedUserData.matches || [];
          const isAlreadyMatched = existingMatches.some(
            (m) => m.email === flightDetails.email
          );

          if (!isAlreadyMatched) {
            await setDoc(
              matchedUserDocRef,
              {
                matches: arrayUnion({
                  id: docRef.id,
                  ...flightDetails,
                  segments: flightSegments,
                }),
              },
              { merge: true }
            );
          }
        }
      }

      // Dynamically update submitted flights without refresh
      setSubmittedFlights((prev) => [
        ...prev,
        { id: docRef.id, ...flightDetails, segments: flightSegments },
      ]);

      // Reset fields
      setFlightSegments([
        {
          flightNumber: "",
          airline: "",
          departure: "",
          departureDate: "",
          departureTime: "",
          arrival: "",
          arrivalDate: "",
          arrivalTime: "",
        },
      ]);
      setFlightDetails({
        fullName: currentUser.displayName || currentUser.email.split("@")[0],
        email: currentUser.email,
        phoneNumber: "",
        age: "",
        notes: "",
        companionType: "",
      });
      setSelectedNotes([]);

      if (matchedItineraries.length > 0) {
        setSuccessMessage(
          "Matches found and Email Notifications Sent! Redirecting to Matches..."
        );
        setTimeout(() => navigate("/matches"), 3000);
      } else {
        setSuccessMessage(null);
        setErrorMessage(
          <Alert
            severity="warning"
            style={{
              backgroundColor: "#fff3cd",
              color: "#856404",
              border: "1px solid #ffeeba",
              marginTop: "20px",
              fontSize: "16px",
            }}
          >
            No matches found for your itinerary. Your details have been saved. You will be notified once a match is available.
          </Alert>
        );
      }
      
    } catch (error) {
      console.error("Error adding flight details:", error);
      setErrorMessage(`Failed to submit flight details: ${error.message}`);
    } finally {
      setLoadingMatches(false);
    }
  };

  return (
    <Box
      mt={4}
      p={4}
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        background: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <AcceptedConnections connections={connections} />
      <HaventBookedFlight />

      {/* Looking for a Companion Section */}
      <Box mb={3}>
        <FormControl component="fieldset">
          <Typography variant="h5" gutterBottom>
            Get Started By Entering Your Flight Details:
          </Typography>
          <RadioGroup
            value={flightDetails.companionType}
            onChange={(e) =>
              setFlightDetails({
                ...flightDetails,
                companionType: e.target.value,
              })
            }
          >
            <FormControlLabel
              value="Looking for a companion"
              required
              control={<Radio color="primary" />}
              label="Looking for a companion"
            />
            <FormControlLabel
              value="Volunteering to be a companion"
              required
              control={<Radio color="primary" />}
              label="Volunteering to be a companion"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <form onSubmit={handleSubmit}>
        <FlightSegmentsForm
          flightSegments={flightSegments}
          handleSegmentChange={handleSegmentChange}
          addSegment={addSegment}
          removeSegment={removeSegment}
        />

        <PassengerDetailsForm
          flightDetails={flightDetails}
          handleChange={handleChange}
          selectedNotes={selectedNotes}
          handleNotesChange={handleNotesChange}
          noteOptions={noteOptions}
          errors={errors}
        />

        {/* New Checkbox for Carrying Items */}
        <Box mt={3}>
          <FormControlLabel
            control={
              <Checkbox
                name="canCarryItems"
                checked={flightDetails.canCarryItems}
                onChange={handleChange}
              />
            }
            label="I am open to sending/bringing urgent items (e.g., documents, medicines) for other people and open to be contacted"
          />
        </Box>

        <Box mt={3} textAlign="center">
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#800040",
              color: "white",
              padding: "10px 20px",
            }}
            disabled={loadingMatches}
          >
            {loadingMatches ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </form>
      <Box mt={3}>
        {successMessage && (
          <Alert severity="success" style={{ marginBottom: "20px" }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && <Box>{errorMessage}</Box>}
      </Box>

      {/* Submitted Flights Section */}
      <SubmittedFlights flights={submittedFlights} />
    </Box>
  );
};

export default FlightForm;
