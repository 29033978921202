import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  Alert,
  CardActions,
  CircularProgress,
} from "@mui/material";
import { db } from "./firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const SearchVolunteers = () => {
  const [month, setMonth] = useState("");
  const [volunteers, setVolunteers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isVolunteer, setIsVolunteer] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [searched, setSearched] = useState(false); // Track if search was clicked


  useEffect(() => {
    const checkIfVolunteer = async () => {
      if (!currentUser) return;

      try {
        const q = query(
          collection(db, "flightDetails"),
          where("email", "==", currentUser.email),
          where("companionType", "==", "Volunteering to be a companion")
        );
        const querySnapshot = await getDocs(q);
        setIsVolunteer(!querySnapshot.empty);
      } catch (err) {
        console.error("Error checking volunteer status:", err);
      }
    };

    checkIfVolunteer();
  }, [currentUser]);

  const handleSearch = async () => {
    if (!month) {
      setError("Please select a month.");
      return;
    }
    setError(null);
    setLoading(true);
    setSearched(true); // Mark that search was initiated

    try {
      const q = query(
        collection(db, "flightDetails"),
        where("companionType", "==", "Volunteering to be a companion")
      );
      const querySnapshot = await getDocs(q);

      const fetchedVolunteers = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const segments = data.segments || [];

        // Filter segments by departureDate range
        const validSegments = segments.filter((segment) => {
          const segmentDate = segment.departureDate; // Ensure segment.departureDate exists
          return segmentDate >= `${month}-01` && segmentDate <= `${month}-31`;
        });

        // Push only if there's at least one valid segment
        if (validSegments.length > 0) {
          fetchedVolunteers.push({
            id: doc.id,
            fullName: data.fullName || "Unknown",
            flightNumber: validSegments[0]?.flightNumber || "N/A",
            airline: validSegments[0]?.airline || "N/A",
            departureDate: validSegments[0]?.departureDate || "N/A",
            departureTime: validSegments[0]?.departureTime || "N/A",
            arrivalDate: validSegments[0]?.arrivalDate || "N/A",
            arrivalTime: validSegments[0]?.arrivalTime || "N/A",
            departure: validSegments[0]?.departure || "N/A",
            arrival: validSegments[0]?.arrival || "N/A",
            age: data.age || "N/A",
            notes: data.notes || "None",
            email: data.email,
            segments: validSegments, // Include all valid segments for navigation
          });
        }
      });

      setVolunteers(fetchedVolunteers);
    } catch (err) {
      console.error("Error fetching volunteers:", err);
      setError("Failed to fetch volunteers. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (volunteer) => {
    // Navigate to UserProfile with volunteer details
    navigate(`/user-profile/${volunteer.id}`, { state: { match: volunteer } });
  };

  return (
    <Box
      mt={4}
      p={4}
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        background: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      {isVolunteer && (
        <Box mb={4}>
          <Card
            style={{
              background: "#f0f4ff",
              borderRadius: "12px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              padding: "20px",
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Manage Your Volunteer Details
              </Typography>
              <Typography variant="body2" color="textSecondary">
                You’ve signed up to be a volunteer companion. Click below to
                review and manage your volunteer flight details.
              </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/manage-volunteer-details")}
                style={{
                  padding: "10px 20px",
                  textTransform: "capitalize",
                }}
              >
                Manage My Volunteer Details
              </Button>
            </CardActions>
          </Card>
        </Box>
      )}

      <Typography variant="h5" gutterBottom>
        Search for Volunteer Companions
      </Typography>

      <Box mb={3}>
        <TextField
          label="Select Month"
          type="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{
            marginBottom: "16px",
          }}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{ display: "block", margin: "0 auto" }}
        >
          Search
        </Button>
      </Box>

      {error && (
        <Alert severity="error" style={{ marginBottom: "16px" }}>
          {error}
        </Alert>
      )}

      {loading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}

      {volunteers.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Volunteer Companions
          </Typography>
          <Grid container spacing={2}>
            {volunteers.map((volunteer) => (
              <Grid item xs={12} sm={6} key={volunteer.id}>
                <Card
                  onClick={() => handleCardClick(volunteer)}
                  style={{
                    cursor: "pointer",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">{volunteer.fullName}</Typography>
                    <Typography variant="body2">
                      <strong>Flight Number:</strong> {volunteer.flightNumber}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Airline:</strong> {volunteer.airline}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Departure:</strong> {volunteer.departure} (
                      {volunteer.departureDate} at {volunteer.departureTime})
                    </Typography>
                    <Typography variant="body2">
                      <strong>Arrival:</strong> {volunteer.arrival} (
                      {volunteer.arrivalDate} at {volunteer.arrivalTime})
                    </Typography>
                    <Typography variant="body2">
                      <strong>Age:</strong> {volunteer.age}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Notes:</strong> {volunteer.notes || "None"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {!loading && searched && volunteers.length === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            backgroundColor: "#ffebee",
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            marginTop: "16px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#d32f2f",
              fontWeight: "bold",
              marginBottom: "8px",
            }}
          >
            No Volunteers Found
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#555",
            }}
          >
            Please try selecting a different month.
          </Typography>
        </Box>
      )}

      {!searched && (
        <Box
          mb={4}
          textAlign="center"
          p={4}
          sx={{ backgroundColor: "#f0f4ff", borderRadius: "12px" }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#2E3B55", fontWeight: "bold" }}
          >
            Have a travel coming up and Interested In Signing Up to Volunteer?
          </Typography>
          <Typography variant="body1" sx={{ color: "#555", marginY: "12px" }}>
            Submit your itinerary and choose the option "Volunteering to be a
            companion." You will be matched with someone who aligns with your
            flight itinerary and help senior citizens enjoy a better travel
            experience.
          </Typography>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              backgroundColor: "#007BFF",
              "&:hover": { backgroundColor: "#0056b3" },
            }}
            onClick={() => navigate("/itinerary")}
          >
            Submit Your Itinerary
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SearchVolunteers;
