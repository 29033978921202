import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Container, CircularProgress, Box  } from "@mui/material";
import Header from "./Header"; // Header component
import ItineraryForm from "./FlightForm";
import RideForm from "./RideForm";
import Matches from "./Matches";
import UserProfile from "./UserProfile"; // New UserProfile component
import ConnectionRequests from "./ConnectionRequests";
import MyProfile from "./MyProfile"; // New MyProfile component
import Welcome from "./Welcome";
import FeedbackForm from "./FeedbackForm";
import Login from "./Login";
import Signup from "./Signup";
import SearchVolunteers from "./SearchVolunteers";
import CourierRequests from "./CourierRequests";
import ManageVolunteerDetails from "./ManageVolunteerDetails";
import { auth } from "./firebase"; // Firebase Authentication
import { onAuthStateChanged } from "firebase/auth";
import { AuthProvider } from "./context/AuthContext";
import SearchCouriers from "./SearchCouriers";

// ProtectedRoute Component
function ProtectedRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Listen to authentication state
  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // Set to true if user exists
      setLoading(false); // Authentication check is done
    });
    return unsubscribe;
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full height for vertical centering
        }}
      >
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
}

function App() {
  // eslint-disable-next-line no-unused-vars
  const [matches, setMatches] = useState([]);

  return (
    <Router>
      <AuthProvider>
        <Header />
        <Container>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/feedback" element={<FeedbackForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/itinerary"
              element={
                <ProtectedRoute>
                  <ItineraryForm setMatches={setMatches} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rides"
              element={
                <ProtectedRoute>
                  <RideForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/matches"
              element={
                <ProtectedRoute>
                  <Matches />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-profile/:id"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/connection-requests"
              element={
                <ProtectedRoute>
                  <ConnectionRequests />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-profile"
              element={
                <ProtectedRoute>
                  <MyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search-volunteers"
              element={
                <ProtectedRoute>
                  <SearchVolunteers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage-volunteer-details"
              element={
                <ProtectedRoute>
                  <ManageVolunteerDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search-couriers"
              element={
                <ProtectedRoute>
                  <SearchCouriers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/courier-requests"
              element={
                <ProtectedRoute>
                  <CourierRequests />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <ItineraryForm setMatches={setMatches} />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Container>
      </AuthProvider>
    </Router>
  );
}

export default App;
