import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Divider,
  Rating,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase";
import { useAuth } from "./context/AuthContext";

function UserProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { match } = location.state || {}; // Match details passed via navigation state

  const [status, setStatus] = useState("idle"); // idle, pending, connected
  const [courierStatus, setCourierStatus] = useState("idle");
  const [loading, setLoading] = useState(true); // Loading state
  const [courierNote, setCourierNote] = useState("");
  const [openDialog, setOpenDialog] = useState(false); // Dialog control
  const [ratings, setRatings] = useState([]); // Ratings for the profile
  const [hasMatches, setHasMatches] = useState(false);

  // Courier options with their associated message template
  const courierTemplates = {
  "Urgent Document": "Hi! I see that you are going from {departure} to {arrival}. Can you please take my urgent documents to the destination?",
  "Medicines": "Hi! I see that you are going from {departure} to {arrival}. Can you please take my medicines to the destination?",
  };

  useEffect(() => {
    if (!currentUser || !match) return;

    const unsubscribeRequests = onSnapshot(
      collection(db, "users", currentUser.email, "connectionRequests"),
      (snapshot) => {
        const requests = snapshot.docs.map((doc) => doc.data());
        const hasRequestFromCurrentUser = requests.some(
          (req) => req.senderEmail === match.email
        );

        if (hasRequestFromCurrentUser) {
          setStatus("pending");
        }
      }
    );

    const checkConnectionStatus = async () => {
      try {
        const userDocRef = doc(db, "users", match.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setRatings(userData.ratings || []);

          const isConnected = userData.connections?.some(
            (conn) => conn.email === currentUser.email
          );

          if (isConnected) {
            setStatus("connected");
          } else {
            const requestsRef = collection(
              db,
              "users",
              match.email,
              "connectionRequests"
            );
            const q = query(
              requestsRef,
              where("senderEmail", "==", currentUser.email)
            );
            const requestSnapshot = await getDocs(q);

            if (!requestSnapshot.empty) {
              setStatus("pending");
            }
          }
        }
      } catch (error) {
        console.error("Error checking connection status:", error);
      } finally {
        setLoading(false);
      }
    };

    const checkCourierRequestStatus = async () => {
      try {
        const userDocRef = doc(db, "users", match.email);

        // Real-time listener to check if the courier request has been accepted
        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();

            // Check if the current user exists in the receiver's couriers field
            const couriers = userData.couriers || [];
            const isAccepted = couriers.some(
              (courier) => courier.email === currentUser.email
            );

            if (isAccepted) setCourierStatus("accepted");
          }
        });

        // Check initial state for courier requests
        const courierRequestsRef = collection(
          db,
          "users",
          match.email,
          "courierRequests"
        );
        const courierQuery = query(
          courierRequestsRef,
          where("senderEmail", "==", currentUser.email)
        );
        const courierSnapshot = await getDocs(courierQuery);

        if (!courierSnapshot.empty) setCourierStatus("pending");

        return () => unsubscribe(); // Cleanup listener
      } catch (error) {
        console.error("Error checking courier request status:", error);
      } finally {
        setLoading(false);
      }
    };

    const checkUserMatches = async () => {
      try {
        const userDocRef = doc(db, "users", match.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setRatings(userData.ratings || []);

          // Check if the matches field has data
          const matches = userData.matches || [];
          if (matches.length > 0) {
            setHasMatches(true);
          }
        }
      } catch (error) {
        console.error("Error checking user matches:", error);
      } finally {
        setLoading(false);
      }
    };

    checkUserMatches();

    checkConnectionStatus();
    checkCourierRequestStatus();

    return () => {
      unsubscribeRequests();
    };
  }, [currentUser, match]);

  const handleSendRequest = async () => {
    try {
      if (!currentUser) {
        alert("You need to log in to send connection requests.");
        navigate("/login");
        return;
      }
  
      // Fetch the current user's flight details
      const flightDetailsQuery = query(
        collection(db, "flightDetails"),
        where("email", "==", currentUser.email)
      );
      const flightDetailsSnapshot = await getDocs(flightDetailsQuery);
  
      let flightDetailsData = {};
      flightDetailsSnapshot.forEach((doc) => {
        flightDetailsData = doc.data(); // Assuming only one document per email
      });
    
      // Safeguard for missing fields
      const senderSegments = flightDetailsData?.segments || [];
  
      // Merge data for the request
      const requestDetails = {
        senderEmail: flightDetailsData?.email || currentUser.email,
        senderName: flightDetailsData?.fullName || currentUser?.displayName || "Anonymous",
        age: flightDetailsData?.age || "N/A",
        notes: flightDetailsData?.notes || "Looking for a companion",
        companionType: flightDetailsData?.companionType || "N/A",
        segments: senderSegments, // Ensure segments is always an array
        timestamp: new Date(),
      };

    
      // Send the connection request
      const recipientRequestsRef = collection(
        db,
        "users",
        match.email,
        "connectionRequests"
      );
  
      await addDoc(recipientRequestsRef, requestDetails);
  
      setStatus("pending");
      alert(`Connection request sent to ${match.fullName || "the user"}`);
    } catch (error) {
      console.error("Error sending connection request:", error);
      alert("Failed to send connection request. Please try again.");
    }
  };

  const handleOpenDialog = () => setOpenDialog(true); // Open dialog
  const handleCloseDialog = () => setOpenDialog(false); // Close dialog

  const handleSendCourierRequest = async () => {
    try {
      if (!currentUser) {
        alert("You need to log in to send courier requests.");
        navigate("/login");
        return;
      }

      const recipientRequestsRef = collection(
        db,
        "users",
        match.email,
        "courierRequests"
      );

      await addDoc(recipientRequestsRef, {
        senderEmail: currentUser.email,
        senderName: currentUser.displayName || currentUser.email.split("@")[0],
        courierNote: courierNote,
        segments: match.segments,
        age: match.age || "N/A",
        notes: match.notes || "None",
        timestamp: new Date(),
      });
      setCourierStatus("pending");
      handleCloseDialog();
      alert(`Courier request sent to ${match.fullName}`);
    } catch (error) {
      console.error("Error sending courier request:", error);
      alert("Failed to send courier request. Please try again.");
    }
  };

  if (!match) {
    return <Typography>No user profile found.</Typography>;
  }

  // Helper function to format the note based on selected option
  const generateCourierNote = (option) => {
    const departure = match?.segments[0]?.departure || "your location";
  const arrival = match?.segments[match.segments.length - 1]?.arrival || "destination";
    return courierTemplates[option]
      .replace("{departure}", departure)
      .replace("{arrival}", arrival);
  };

  return (
    <Box mt={4} p={4} style={{ maxWidth: "800px", margin: "0 auto" }}>
      <Card
        style={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {match.fullName}'s Profile
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
          <Typography variant="h6">Personal Details</Typography>
          {(status === "connected" || courierStatus === "accepted") && (
            <Typography variant="body1">
              <strong>Email:</strong> {match.email}
            </Typography>
          )}
          <Typography variant="body1">
            <strong>Age:</strong> {match.age || "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Companion Type:</strong> {match.companionType}
          </Typography>
          <Typography variant="body1">
            <strong>Notes:</strong> {match.notes || "None"}
          </Typography>
          <Box
            style={{
              marginTop: "20px",
              padding: "15px",
              backgroundColor: "#e3f7ff",
              border: "1px solid #b3e5fc",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <InfoOutlinedIcon
              style={{
                color: "#0288d1",
                fontSize: "30px",
                marginRight: "15px",
              }}
            />
            <Typography
              variant="body2"
              style={{ color: "#333", fontSize: "1rem", lineHeight: "1.5" }}
            >
              You will be able to see contact info once both of you are
              connected or accept the request.
            </Typography>
          </Box>
          <Divider style={{ margin: "20px 0" }} />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="flight-journey-content"
              id="flight-journey-header"
            >
              <Typography variant="h6">Flight Journey</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                sx={{
                  width: "100%", // Ensure the box spans the full width
                  padding: { xs: "0 8px", sm: "0" }, // Add padding on small screens
                  boxSizing: "border-box",
                }}
              >
                {match.segments.map((segment, index) => (
                  <React.Fragment key={index}>
                    <Box
                      sx={{
                        backgroundColor: "#f5f5f5",
                        padding: "15px",
                        borderRadius: "10px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        margin: "5px",
                        width: "100%", // Use full width of container
                        maxWidth: "600px", // Constrain width for larger screens
                        boxSizing: "border-box", // Include padding in width calculation
                      }}
                    >
                      <Box display="flex" alignItems="center" mb={1}>
                        <FlightTakeoffIcon
                          style={{ color: "#4caf50", marginRight: "8px" }}
                        />
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          {segment.departure} ({segment.departureDate} at{" "}
                          {segment.departureTime})
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <FlightLandIcon
                          style={{ color: "#f44336", marginRight: "8px" }}
                        />
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          {segment.arrival} ({segment.arrivalDate} at{" "}
                          {segment.arrivalTime})
                        </Typography>
                      </Box>
                      <Divider style={{ margin: "8px 0" }} />
                      <Typography variant="body2">
                        <strong>Flight Number:</strong> {segment.flightNumber}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Airline:</strong> {segment.airline}
                      </Typography>
                    </Box>
                    {index < match.segments.length - 1 && (
                      <ArrowForwardIcon
                        style={{
                          margin: "0 15px",
                          color: "#1976d2",
                          fontSize: "32px",
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box mt={3}>
            <Grid
              container
              spacing={2}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <Grid item xs={12} sm="auto">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(-1)}
                  fullWidth
                >
                  Back
                </Button>
              </Grid>
              {match?.canCarryItems && (
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleOpenDialog}
                    disabled={
                      courierStatus === "pending" ||
                      courierStatus === "accepted"
                    }
                    fullWidth
                  >
                    {courierStatus === "pending"
                      ? "Pending Courier Request"
                      : courierStatus === "accepted"
                      ? "Courier Accepted"
                      : "Send Courier Request"}
                  </Button>
                </Grid>
              )}
              {courierStatus === "accepted" && (
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/courier-requests")}
                    startIcon={<ArrowForwardIcon />}
                    fullWidth
                  >
                    Manage This Courier Request
                  </Button>
                </Grid>
              )}
              {loading ? (
                <Grid item xs={12} sm="auto">
                  <Button variant="contained" disabled fullWidth>
                    Checking...
                  </Button>
                </Grid>
              ) : status === "connected" ? (
                <Grid item xs={12} sm="auto">
                  <Button
                    variant="contained"
                    color="success"
                    disabled
                    fullWidth
                  >
                    Connection Accepted
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} sm="auto">
                  {!loading && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSendRequest}
                      disabled={status === "pending"}
                      fullWidth
                    >
                      {status === "pending"
                        ? "Pending Connection Request"
                        : "Send Connection Request"}
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Send Courier Request</DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            Write a short note for the recipient regarding your courier request.
          </Typography>

          {/* TextField for Courier Note */}
          <TextField
            label="Courier Note"
            placeholder="e.g., Please carry a small package for me."
            multiline
            rows={3}
            fullWidth
            value={courierNote}
            onChange={(e) => setCourierNote(e.target.value)}
            variant="outlined"
            margin="normal"
          />

          {/* Options for Quick Selection */}
          <Box mt={2}>
            <Typography variant="body2" gutterBottom>
              Select an option to auto-generate the note:
            </Typography>
            <Box display="flex" gap={1} flexWrap="wrap">
              {Object.keys(courierTemplates).map((option) => (
                <Button
                  key={option}
                  variant="outlined"
                  size="small"
                  onClick={() => setCourierNote(generateCourierNote(option))}
                >
                  {option}
                </Button>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSendCourierRequest}
            color="primary"
            variant="contained"
            disabled={!courierNote.trim()}
          >
            Send Request
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          User Ratings and Reviews
        </Typography>
        {ratings.length > 0 ? (
          <Grid container spacing={2}>
            {ratings.map((rating, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="body1">
                      <strong>Rating:</strong>{" "}
                      <Rating value={rating.rating} readOnly />
                    </Typography>
                    <Typography variant="body1">
                      <strong>Comment:</strong>{" "}
                      {rating.comment || "No comment provided"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Reviewer:</strong> {rating.reviewer}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No ratings or reviews yet.</Typography>
        )}
      </Box>
    </Box>
  );
}

export default UserProfile;
