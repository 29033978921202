import React from "react";
import { Button, Typography, Box, Container, Grid, Card, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import PeopleIcon from "@mui/icons-material/People";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SearchIcon from "@mui/icons-material/Search";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import MedicationIcon from "@mui/icons-material/Medication";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dashboard from "./Dashboard";


function Welcome() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleGetStarted = () => {
    if (currentUser) {
      navigate("/itinerary");
    } else {
      navigate("/login");
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box>
      {/* Support Card for Nepali Flights */}
      <Container maxWidth="sm" style={{ marginTop: "30px", marginBottom: "30px" }}>
        <Card
          style={{
            padding: "15px",
            textAlign: "center",
            backgroundColor: "#e8f5e9",
            border: "1px solid #66bb6a",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              color: "#388e3c",
            }}
          >
            <FlightTakeoffIcon style={{ fontSize: "30px" }} />
            USA ⇆ Nepal Flights Supported
          </Typography>
          <Typography variant="body2" style={{ marginTop: "5px" }}>
            We currently provide support for flights between the United States
            and Nepal. Find companions or couriers for your journey or
            deliveries to and from Nepal!
          </Typography>
        </Card>
      </Container>
      <Dashboard />

      {/* Main Welcome Section */}
      <Container
        maxWidth="sm"
        style={{
          textAlign: "center",
          padding: "50px",
          marginTop: "30px",
          borderRadius: "10px",
          background: "#f9f9f9",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to Flight Friend
        </Typography>
        <Typography variant="body1" gutterBottom>
          We help you find flight companions, especially for individuals like
          senior citizens who may need company during their journey. Volunteer
          to be a companion or find a companion today!
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleGetStarted}
          >
            Get Started
          </Button>
        </Box>
        <Box mt={4}>
          <Typography variant="body1">
            Haven't booked a flight yet? Search for a specific timeline in our
            database and find companions and book your flight accordingly.
          </Typography>
        </Box>
      </Container>

      {/* Feature Highlights Section */}
      <Box>
      {/* Feature Highlights Section */}
      <Container maxWidth="lg" style={{ marginTop: "50px" }}>
        <Typography variant="h4" gutterBottom align="center">
          Main Features
        </Typography>
        <Grid container spacing={3}>
          {/* Find Flight Companion Card */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              onClick={() => handleNavigate("/itinerary")}
              sx={{
                cursor: "pointer",
                position: "relative",
                "&:hover": {
                  transform: { xs: "none", sm: "scale(1.05)" },
                  boxShadow: { xs: "none", sm: "0 6px 15px rgba(0, 0, 0, 0.2)" },
                },
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Card
                style={{
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <PeopleIcon color="primary" style={{ fontSize: "40px" }} />
                <Typography variant="h6">Find Flight Companion</Typography>
                <Typography variant="body2">
                  Find companions for your journey to make traveling safer and
                  more enjoyable, especially for those traveling alone or needing
                  assistance.
                </Typography>
                {/* Arrow Icon for Small Screens */}
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ArrowForwardIcon color="primary" />
                </Box>
              </Card>
            </Box>
          </Grid>

          {/* Urgent Couriers Card */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              onClick={() => handleNavigate("/search-couriers")}
              sx={{
                cursor: "pointer",
                position: "relative",
                "&:hover": {
                  transform: { xs: "none", sm: "scale(1.05)" },
                  boxShadow: { xs: "none", sm: "0 6px 15px rgba(0, 0, 0, 0.2)" },
                },
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Card
                style={{
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <MedicationIcon color="primary" style={{ fontSize: "40px" }} />
                <Typography variant="h6">Urgent Couriers</Typography>
                <Typography variant="body2">
                  Need to send urgent medicines or documents? Find a courier or
                  volunteer to deliver items.
                </Typography>
                {/* Arrow Icon for Small Screens */}
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ArrowForwardIcon color="primary" />
                </Box>
              </Card>
            </Box>
          </Grid>

          {/* Volunteer to be a Companion Card */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              onClick={() => handleNavigate("/itinerary")}
              sx={{
                cursor: "pointer",
                position: "relative",
                "&:hover": {
                  transform: { xs: "none", sm: "scale(1.05)" },
                  boxShadow: { xs: "none", sm: "0 6px 15px rgba(0, 0, 0, 0.2)" },
                },
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Card
                style={{
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <VolunteerActivismIcon
                  color="primary"
                  style={{ fontSize: "40px" }}
                />
                <Typography variant="h6">Volunteer to be a Companion</Typography>
                <Typography variant="body2">
                  Help others by volunteering as a travel companion and make a
                  positive impact.
                </Typography>
                {/* Arrow Icon for Small Screens */}
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ArrowForwardIcon color="primary" />
                </Box>
              </Card>
            </Box>
          </Grid>

          {/* Haven't Booked a Flight Yet Card */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              onClick={() => handleNavigate("/search-volunteers")}
              sx={{
                cursor: "pointer",
                position: "relative",
                "&:hover": {
                  transform: { xs: "none", sm: "scale(1.05)" },
                  boxShadow: { xs: "none", sm: "0 6px 15px rgba(0, 0, 0, 0.2)" },
                },
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Card
                style={{
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <FlightTakeoffIcon color="primary" style={{ fontSize: "40px" }} />
                <Typography variant="h6">Haven't Booked a Flight Yet?</Typography>
                <Typography variant="body2">
                  Search for volunteers and plan your trip accordingly.
                </Typography>
                {/* Arrow Icon for Small Screens */}
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <ArrowForwardIcon color="primary" />
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>

      {/* How It Works Section */}
      <Container maxWidth="lg" style={{ marginTop: "50px" }}>
        <Typography variant="h4" gutterBottom align="center">
          How It Works
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Card
              style={{ textAlign: "center", padding: "20px", height: "100%" }}
            >
              <FlightTakeoffIcon color="primary" style={{ fontSize: "40px" }} />
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Step 1: Add Your Itinerary
              </Typography>
              <Typography variant="body2">
                Enter your flight details to get started.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              style={{ textAlign: "center", padding: "20px", height: "100%" }}
            >
              <SearchIcon color="primary" style={{ fontSize: "40px" }} />
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Step 2: Find Matches
              </Typography>
              <Typography variant="body2">
                We scan our database to find the best matches for your flight
                details and you will get an email once a match is found.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              style={{ textAlign: "center", padding: "20px", height: "100%" }}
            >
              <LocalShippingIcon color="primary" style={{ fontSize: "40px" }} />
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Step 3: Connect or Deliver
              </Typography>
              <Typography variant="body2">
                Send connection requests or coordinate urgent deliveries.
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Box mt={6} textAlign="center">
          <Typography variant="body2">
            Have suggestions or feedback?{" "}
            <Button
              variant="text"
              onClick={() => navigate("/feedback")}
              style={{ textTransform: "none", color: "blue" }}
            >
              Click here to let us know.
            </Button>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Welcome;
