import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Autocomplete,
} from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import { db } from "./firebase";
import { collection, query, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import airportOptions from "./airportOptions"; // List of airport options
import TravelFeed from "./TravelFeed";

const Dashboard = () => {
  const [month, setMonth] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [companions, setCompanions] = useState([]);
  const [volunteers, setVolunteers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false); // New state
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleSearch = async () => {
    if (!month && !departure && !arrival) {
      setError("Please provide at least one search criterion.");
      return;
    }
    setError(null);
    setLoading(true);
    setSearchPerformed(true); // Mark search as performed
  
    try {
      const q = query(collection(db, "flightDetails"));
      const querySnapshot = await getDocs(q);
  
      const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
      const fetchedCompanions = [];
      const fetchedVolunteers = [];
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const segments = data.segments || [];
  
        const validSegments = segments.filter((segment) => {
          const segmentDate = segment.departureDate;
          const isTodayOrLater = segmentDate >= today; // Check if the date is today or later
          const matchesMonth = month
            ? segmentDate >= `${month}-01` && segmentDate <= `${month}-31`
            : true;
          const matchesDeparture = departure
            ? segment.departure.toLowerCase() === departure.toLowerCase()
            : true;
          const matchesArrival = arrival
            ? segment.arrival.toLowerCase() === arrival.toLowerCase()
            : true;
  
          return isTodayOrLater && matchesMonth && matchesDeparture && matchesArrival;
        });
  
        if (validSegments.length > 0) {
          const firstSegment = validSegments[0];
          const lastSegment = validSegments[validSegments.length - 1];
  
          const entry = {
            id: doc.id,
            fullName: "Flight Friend User",
            age: "Redacted",
            flightNumber: firstSegment.flightNumber || "N/A",
            airline: firstSegment.airline || "N/A",
            departureDate: firstSegment.departureDate || "N/A",
            departureTime: firstSegment.departureTime || "N/A",
            arrivalDate: lastSegment.arrivalDate || "N/A",
            arrivalTime: lastSegment.arrivalTime || "N/A",
            departure: firstSegment.departure || "N/A",
            arrival: lastSegment.arrival || "N/A",
            notes: "Redacted",
            companionType: data.companionType,
            segments: validSegments,
            email: data.email || null,
          };
  
          if (data.companionType === "Looking for a companion") {
            fetchedCompanions.push(entry);
          } else if (data.companionType === "Volunteering to be a companion") {
            fetchedVolunteers.push(entry);
          }
        }
      });
  
      // Sort both lists by departureDate
      const sortByDate = (a, b) =>
        new Date(a.departureDate) - new Date(b.departureDate);
  
      setCompanions(fetchedCompanions.sort(sortByDate));
      setVolunteers(fetchedVolunteers.sort(sortByDate));
    } catch (err) {
      console.error("Error fetching entries:", err);
      setError("Failed to fetch entries. Please try again.");
    } finally {
      setLoading(false);
    }
  };
   

  const handleCardClick = (entry) => {
    if (!currentUser) {
      return alert("You need to log in or sign up to send a connection request.");
    }
    navigate(`/user-profile/${entry.id}`, { state: { match: entry } });
  };

  return (
    <Box
      mt={4}
      p={4}
      sx={{
        maxWidth: "900px",
        margin: "0 auto",
        background: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography variant="h4" gutterBottom align="center" sx={{ color: "black" }}>
        Dashboard: Search For Travelers
      </Typography>
      <Box mb={3}>
        <TextField
          label="Select Month"
          type="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          style={{ marginBottom: "16px" }}
        />
        <Autocomplete
          options={airportOptions}
          getOptionLabel={(option) => option.label || option}
          renderInput={(params) => (
            <TextField {...params} label="Departure Airport" variant="outlined" />
          )}
          value={departure}
          onChange={(e, value) => setDeparture(value?.label || value || "")}
          fullWidth
          style={{ marginBottom: "16px" }}
        />
        <Autocomplete
          options={airportOptions}
          getOptionLabel={(option) => option.label || option}
          renderInput={(params) => (
            <TextField {...params} label="Arrival Airport" variant="outlined" />
          )}
          value={arrival}
          onChange={(e, value) => setArrival(value?.label || value || "")}
          fullWidth
          style={{ marginBottom: "16px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          fullWidth
          sx={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            "&:hover": { backgroundColor: "#0056b3" },
          }}
        >
          Search
        </Button>
      </Box>

      {error && <Alert severity="error">{error}</Alert>}

      {loading ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : searchPerformed ? (
        <>
          {companions.length > 0 ? (
            <>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ mt: 4, color: "#333", textAlign: "center", fontWeight: "bold" }}
              >
                Looking for a Companion
              </Typography>
              <Grid container spacing={2}>
                {companions.map((entry, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card
                      sx={{
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      onClick={() => handleCardClick(entry)}
                    >
                      <CardContent>
                        <Typography
                          variant="h6"
                          sx={{ color: "#007bff", fontWeight: "bold", mb: 2 }}
                        >
                          {entry.fullName}
                        </Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FlightTakeoffIcon sx={{ color: "#4caf50", mr: 1 }} />
                          <Typography variant="body1">
                            {entry.departure} - {entry.departureDate} at {entry.departureTime}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FlightLandIcon sx={{ color: "#f44336", mr: 1 }} />
                          <Typography variant="body1">
                            {entry.arrival} - {entry.arrivalDate} at {entry.arrivalTime}
                          </Typography>
                        </Box>
                        <Typography variant="body2">
                          <strong>Flight Number:</strong> {entry.flightNumber}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Airline:</strong> {entry.airline}
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{
                            mt: 2,
                            backgroundColor: "#007bff",
                            "&:hover": { backgroundColor: "#0056b3" },
                          }}
                          onClick={() => handleCardClick(entry)}
                        >
                          Send Connection Request
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Typography
              variant="h6"
              sx={{ mt: 4, textAlign: "center", color: "#555" }}
            >
              No results found for companions.
            </Typography>
          )}

          {volunteers.length > 0 ? (
            <>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ mt: 4, color: "#333", textAlign: "center", fontWeight: "bold" }}
              >
                Volunteering to be a Companion
              </Typography>
              <Grid container spacing={2}>
                {volunteers.map((entry, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card
                      sx={{
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      onClick={() => handleCardClick(entry)}
                    >
                      <CardContent>
                        <Typography
                          variant="h6"
                          sx={{ color: "#007bff", fontWeight: "bold", mb: 2 }}
                        >
                          {entry.fullName}
                        </Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FlightTakeoffIcon sx={{ color: "#4caf50", mr: 1 }} />
                          <Typography variant="body1">
                            {entry.departure} - {entry.departureDate} at {entry.departureTime}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FlightLandIcon sx={{ color: "#f44336", mr: 1 }} />
                          <Typography variant="body1">
                            {entry.arrival} - {entry.arrivalDate} at {entry.arrivalTime}
                          </Typography>
                        </Box>
                        <Typography variant="body2">
                          <strong>Flight Number:</strong> {entry.flightNumber}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Airline:</strong> {entry.airline}
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{
                            mt: 2,
                            backgroundColor: "#007bff",
                            "&:hover": { backgroundColor: "#0056b3" },
                          }}
                          onClick={() => handleCardClick(entry)}
                        >
                          Send Connection Request
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Typography
              variant="h6"
              sx={{ mt: 4, textAlign: "center", color: "#555" }}
            >
              No results found for volunteers.
            </Typography>
          )}
        </>
      ) : null}
      <TravelFeed />
    </Box>
  );
};

export default Dashboard;
