import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  TextField,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import { db } from "./firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import { useAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";

const RideForm = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [view, setView] = useState("post");
  const [rideDetails, setRideDetails] = useState({
    pickupState: "",
    destinationState: "",
    travelDate: "",
    availableSeats: "",
    notes: "",
  });
  const [searchDetails, setSearchDetails] = useState({
    pickupState: "",
    destinationState: "",
    travelDate: "",
    travelMonth: "",
  });
  const [rides, setRides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleRideChange = (e) => {
    const { name, value } = e.target;
    setRideDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleRideSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    if (
      !rideDetails.pickupState ||
      !rideDetails.destinationState ||
      !rideDetails.travelDate ||
      !rideDetails.availableSeats
    ) {
      setErrorMessage("All fields are required.");
      setLoading(false);
      return;
    }

    try {
      // Add ride details to Firebase
      const docRef = await addDoc(collection(db, "rides"), {
        ...rideDetails,
        userEmail: currentUser.email,
      });

      // Matching Logic
      const matchQuery = query(
        collection(db, "rides"),
        where("pickupState", "==", rideDetails.pickupState),
        where("destinationState", "==", rideDetails.destinationState)
      );

      const querySnapshot = await getDocs(matchQuery);

      const matchedRides = [];
      querySnapshot.forEach((doc) => {
        const rideData = doc.data();
        if (rideData.userEmail !== currentUser.email) {
          matchedRides.push({ id: doc.id, ...rideData });
        }
      });

      if (matchedRides.length > 0) {
        // Save matches in the user's document
        const userDocRef = doc(db, "users", currentUser.email);
        await setDoc(
          userDocRef,
          { matches: arrayUnion(...matchedRides) },
          { merge: true }
        );

        setSuccessMessage("Matches found! Redirecting to matches...");
        setTimeout(() => navigate("/matches"), 3000);
      } else {
        setSuccessMessage("No matches found. Your ride has been posted.");
      }

      setRideDetails({
        pickupState: "",
        destinationState: "",
        travelDate: "",
        availableSeats: "",
        notes: "",
      });
    } catch (error) {
      console.error("Error posting ride:", error);
      setErrorMessage("Failed to post ride. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    if (!searchDetails.pickupState || !searchDetails.destinationState) {
      setErrorMessage("Please provide both Pickup and Destination.");
      setLoading(false);
      return;
    }

    try {
      const searchQuery = query(
        collection(db, "rides"),
        where("pickupState", "==", searchDetails.pickupState),
        where("destinationState", "==", searchDetails.destinationState)
      );

      const querySnapshot = await getDocs(searchQuery);

      const matchedRides = [];
      querySnapshot.forEach((doc) => {
        const rideData = doc.data();
        const rideDate = rideData.travelDate;

        if (
          searchDetails.travelDate &&
          rideDate === searchDetails.travelDate
        ) {
          matchedRides.push({ id: doc.id, ...rideData });
        } else if (
          searchDetails.travelMonth &&
          rideDate.startsWith(searchDetails.travelMonth)
        ) {
          matchedRides.push({ id: doc.id, ...rideData });
        }
      });

      if (matchedRides.length > 0) {
        setRides(matchedRides);
        setSuccessMessage("Matching rides found!");
      } else {
        setSuccessMessage("No matching rides found.");
      }
    } catch (error) {
      console.error("Error searching for rides:", error);
      setErrorMessage("Failed to search for rides. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      mt={4}
      sx={{
        maxWidth: "800px",
        margin: "0 auto",
        background: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Card>
        <CardContent>
          <Box textAlign="center" mb={3}>
            <Button
              onClick={() => setView("post")}
              variant={view === "post" ? "contained" : "outlined"}
              sx={{ marginRight: "10px" }}
            >
              Post a Ride
            </Button>
            <Button
              onClick={() => setView("search")}
              variant={view === "search" ? "contained" : "outlined"}
            >
              Search for Rides
            </Button>
          </Box>

          {view === "post" && (
            <>
              <Typography variant="h5" gutterBottom>
                Post a Ride
              </Typography>
              <form onSubmit={handleRideSubmit}>
                <TextField
                  label="Pickup State"
                  name="pickupState"
                  value={rideDetails.pickupState}
                  onChange={handleRideChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Destination State"
                  name="destinationState"
                  value={rideDetails.destinationState}
                  onChange={handleRideChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Travel Date"
                  name="travelDate"
                  type="date"
                  value={rideDetails.travelDate}
                  onChange={handleRideChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <TextField
                  label="Available Seats"
                  name="availableSeats"
                  type="number"
                  value={rideDetails.availableSeats}
                  onChange={handleRideChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Notes"
                  name="notes"
                  value={rideDetails.notes}
                  onChange={handleRideChange}
                  fullWidth
                  margin="normal"
                  helperText="E.g., Looking for a travel companion, travel duration, splitting gas, or song preferences."
                />
                <Box mt={3} textAlign="center">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#007bff",
                      "&:hover": { backgroundColor: "#0056b3" },
                      padding: "10px 20px",
                    }}
                    disabled={loading}
                  >
                    {loading ? "Posting..." : "Post Ride"}
                  </Button>
                </Box>
              </form>
            </>
          )}

          {view === "search" && (
            <>
              <Typography variant="h5" gutterBottom>
                Search for Rides
              </Typography>
              <Box mt={3}>
                <TextField
                  label="Pickup State"
                  name="pickupState"
                  value={searchDetails.pickupState}
                  onChange={handleSearchChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Destination State"
                  name="destinationState"
                  value={searchDetails.destinationState}
                  onChange={handleSearchChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Travel Date (Optional)"
                  name="travelDate"
                  type="date"
                  value={searchDetails.travelDate}
                  onChange={handleSearchChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Travel Month (YYYY-MM, Optional)"
                  name="travelMonth"
                  type="month"
                  value={searchDetails.travelMonth}
                  onChange={handleSearchChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
                <Box mt={3} textAlign="center">
                  <Button
                    onClick={handleSearch}
                    variant="contained"
                    sx={{
                      backgroundColor: "#28a745",
                      "&:hover": { backgroundColor: "#218838" },
                      padding: "10px 20px",
                    }}
                  >
                    {loading ? "Searching..." : "Search Rides"}
                  </Button>
                </Box>
              </Box>
            </>
          )}

          <Box mt={3}>
            {errorMessage && (
              <Alert severity="error" style={{ marginBottom: "20px" }}>
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" style={{ marginBottom: "20px" }}>
                {successMessage}
              </Alert>
            )}
          </Box>

          {rides.length > 0 && view === "search" && (
            <Box mt={3}>
              <Typography variant="h6" gutterBottom>
                Matching Rides:
              </Typography>
              {rides.map((ride) => (
                <Box
                  key={ride.id}
                  p={2}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    background: "#fff",
                    marginBottom: "10px",
                  }}
                >
                  <Typography>
                    <strong>Pickup:</strong> {ride.pickupState}
                  </Typography>
                  <Typography>
                    <strong>Destination:</strong> {ride.destinationState}
                  </Typography>
                  <Typography>
                    <strong>Date:</strong> {ride.travelDate}
                  </Typography>
                  <Typography>
                    <strong>Available Seats:</strong> {ride.availableSeats}
                  </Typography>
                  {ride.notes && (
                    <Typography>
                      <strong>Notes:</strong> {ride.notes}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default RideForm;
