import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Card,
  Divider,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { db } from "./firebase";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  orderBy,
  query,
  onSnapshot
} from "firebase/firestore";
import { useAuth } from "./context/AuthContext";

const TravelFeed = () => {
  const { currentUser } = useAuth();
  const [travelFeed, setTravelFeed] = useState([]);
  const [newFeed, setNewFeed] = useState({ content: "", type: "" });
  const [postingFeed, setPostingFeed] = useState(false);
  const [localComments, setLocalComments] = useState({});
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const unsubscribe = fetchTravelFeedRealTime();
    return () => unsubscribe();
  }, []);

  const fetchTravelFeedRealTime = () => {
    const feedQuery = query(
      collection(db, "travelFeed"),
      orderBy("timestamp", "desc")
    );
  
    return onSnapshot(
      feedQuery,
      (snapshot) => {
        const feedData = snapshot.docs.map((doc) => {
          const data = doc.data();
          if (!currentUser) {
            // Remove sensitive data if user is not authenticated
            delete data.author;
          }
          return { id: doc.id, ...data };
        });
        setTravelFeed(feedData);
  
        const initialComments = feedData.reduce((acc, feed) => {
          acc[feed.id] = "";
          return acc;
        }, {});
        setLocalComments(initialComments);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching travel feed:", error);
        setLoading(false);
      }
    );
  };  

  const showLoginDialog = () => {
    setOpenDialog(true);
  };

  const closeLoginDialog = () => {
    setOpenDialog(false);
  };

  const handleFeedPost = async () => {
    if (!currentUser) {
      showLoginDialog();
      return;
    }
    if (!newFeed.content.trim()) {
      alert("Feed content cannot be empty.");
      return;
    }
    setPostingFeed(true);
    try {
      await addDoc(collection(db, "travelFeed"), {
        content: newFeed.content,
        type: newFeed.type,
        author: currentUser?.email || "Anonymous",
        timestamp: new Date().toISOString(),
        comments: [],
      });
      setNewFeed({ content: "", type: "" });
    } catch (err) {
      console.error("Error posting to travel feed:", err);
      alert("Failed to post feed. Please try again.");
    } finally {
      setPostingFeed(false);
    }
  };

  const handleCommentSubmit = async (postId) => {
    if (!currentUser) {
      showLoginDialog();
      return;
    }
    if (!localComments[postId].trim()) {
      alert("Comment cannot be empty.");
      return;
    }
    try {
      const postRef = doc(db, "travelFeed", postId);
      const updatedComments = [
        ...travelFeed.find((feed) => feed.id === postId).comments,
        {
          text: localComments[postId],
          author: currentUser?.email || "Anonymous",
          timestamp: new Date().toISOString(),
        },
      ];
      await updateDoc(postRef, { comments: updatedComments });
      setLocalComments((prev) => ({ ...prev, [postId]: "" }));
    } catch (err) {
      console.error("Error adding comment:", err);
      alert("Failed to add comment. Please try again.");
    }
  };

  const autoPopulateFlight = () => {
    if (!currentUser) {
      showLoginDialog();
      return;
    }
    setNewFeed({
      content:
        "Is anyone planning to travel from [Airport 1] to [Airport 2] on [Date]? Please contact me at [Contact]",
      type: "flight",
    });
  };

  const autoPopulateRoadTrip = () => {
    if (!currentUser) {
      showLoginDialog();
      return;
    }
    setNewFeed({
      content:
        "I am planning to do a road trip from [State 1] to [State 2] and looking for a company. Please reach out to me if you are interested",
      type: "roadTrip",
    });
  };

  return (
    <Box sx={{ mt: 4, px: { xs: 2, sm: 4, md: 6 } }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontSize: { xs: "1.2rem", md: "1.5rem" } }}
      >
        Travel Feed
      </Typography>
      <Box
        mb={2}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: { xs: "center", md: "flex-start" },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={autoPopulateFlight}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          Auto-Populate Flight
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={autoPopulateRoadTrip}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          Auto-Populate Road Trip
        </Button>
      </Box>
      <TextField
        label="Post about your travel plans"
        placeholder="E.g., Planning a trip from the Northeast to the South this December. Let's share a ride!"
        multiline
        rows={3}
        value={newFeed.content}
        onChange={(e) => setNewFeed({ ...newFeed, content: e.target.value })}
        fullWidth
        variant="outlined"
      />
      <Button
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: "#007bff",
          "&:hover": { backgroundColor: "#0056b3" },
          width: { xs: "100%", md: "auto" },
        }}
        onClick={handleFeedPost}
        disabled={postingFeed}
      >
        {postingFeed ? "Posting..." : "Post"}
      </Button>

      {loading ? (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress />
        </Box>
      ) : travelFeed.length === 0 ? (
        <Typography
          mt={3}
          textAlign="center"
          sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
        >
          No travel feed posts yet.
        </Typography>
      ) : (
        <Box mt={3}>
          {travelFeed.map((feed) => (
            <Card
              key={feed.id}
              sx={{
                mb: 2,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
                padding: { xs: 1.5, md: 2 },
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
                >
                  {feed.content}
                </Typography>
                {feed.type === "flight" && (
                  <Chip
                    icon={<AirplanemodeActiveIcon />}
                    label="Flight"
                    color="primary"
                    sx={{ fontSize: { xs: "0.7rem", md: "0.9rem" } }}
                  />
                )}
                {feed.type === "roadTrip" && (
                  <Chip
                    icon={<DirectionsCarIcon />}
                    label="Road Trip"
                    color="secondary"
                    sx={{ fontSize: { xs: "0.7rem", md: "0.9rem" } }}
                  />
                )}
              </Box>
              <Divider sx={{ my: 1 }} />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: { xs: "0.8rem", md: "0.9rem" } }}
              >
                Posted by: {feed.author ? feed.author : "Flight Friend User"}
              </Typography>

              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Comments:
                </Typography>
                {feed.comments?.length > 0 ? (
                  feed.comments.map((comment, index) => (
                    <Box key={index} mt={1}>
                      <Typography variant="body2">
                        <strong>
                          {" "}
                          {comment.author
                            ? comment.author
                            : "Flight Friend User"}
                          :
                        </strong>{" "}
                        {comment.text}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No comments yet.
                  </Typography>
                )}
              </Box>
              <TextField
                label="Add a comment"
                variant="outlined"
                fullWidth
                size="small"
                sx={{ mt: 1 }}
                value={localComments[feed.id] || ""}
                onChange={(e) =>
                  setLocalComments((prev) => ({
                    ...prev,
                    [feed.id]: e.target.value,
                  }))
                }
              />
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 1 }}
                onClick={() => handleCommentSubmit(feed.id)}
              >
                Comment
              </Button>
            </Card>
          ))}
        </Box>
      )}

      {/* Pop-Up Dialog */}
      <Dialog open={openDialog} onClose={closeLoginDialog}>
        <DialogTitle>
          <WarningAmberIcon sx={{ mr: 1, color: "orange" }} />
          Login Required
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You need to be logged in to perform this action. Please log in to
            continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLoginDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TravelFeed;
