import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Alert,
  CardActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EventIcon from "@mui/icons-material/Event";
import { useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useAuth } from "./context/AuthContext";

// Mock mapping of airport codes to states
const airportStates = {
  JFK: "New York",
  LAX: "California",
  ORD: "Illinois",
  ATL: "Georgia",
  DFW: "Texas",
  // Add more mappings as needed
};

const SearchCouriers = () => {
  const [couriers, setCouriers] = useState([]);
  const [month, setMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searched, setSearched] = useState(false); // Flag to check if search was triggered
  const { currentUser } = useAuth();
  const navigate = useNavigate(); // For navigation

  const fetchCouriers = async () => {
    if (!month) {
      setError("Please select a month before searching.");
      return;
    }

    setLoading(true);
    setError(null);
    setSearched(true); // Mark search as triggered

    try {
      const q = query(
        collection(db, "flightDetails"),
        where("canCarryItems", "==", true) // Filter for users who can carry items
      );
      const querySnapshot = await getDocs(q);

      const fetchedCouriers = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const segments = data.segments || [];

        // Exclude the current user's data
        if (data.email === currentUser.email) return;

        // Filter segments by selected month
        const validSegments = segments.filter((segment) =>
          segment.departureDate.startsWith(month)
        );

        if (validSegments.length > 0) {
          fetchedCouriers.push({
            id: doc.id,
            email: data.email, // Ensure email is captured for navigation
            fullName: data.fullName || "Unknown",
            age: data.age || "N/A",
            notes: data.notes || "None",
            canCarryItems: data.canCarryItems,
            segments: validSegments,
          });
        }
      });

      setCouriers(fetchedCouriers);
    } catch (err) {
      console.error("Error fetching couriers:", err);
      setError("Failed to fetch couriers. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSendCourierRequest = (courier) => {
    if (!courier || !courier.email) {
      alert("Invalid courier details. Please try again.");
      return;
    }

    navigate(`/user-profile/${courier.id}`, {
      state: {
        match: courier,
      },
    });
  };

  return (
    <Box
      mt={4}
      p={4}
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        background: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Search for Travelers Open to Courier of Urgent Items
      </Typography>

      <Box mb={3}>
        <TextField
          label="Select Month"
          type="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{
            marginBottom: "16px",
          }}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={fetchCouriers}
          style={{ display: "block", margin: "0 auto" }}
        >
          Search
        </Button>
      </Box>

      {error && (
        <Alert severity="error" style={{ marginBottom: "16px" }}>
          {error}
        </Alert>
      )}

      {!searched && (
        <Box
          mt={4}
          p={3}
          style={{
            background: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              color: "#3f51b5",
              marginBottom: "16px",
              textAlign: "center",
            }}
          >
            What is this Courier Feature and How Does it Work?
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "#555", lineHeight: "1.6", marginBottom: "12px" }}
          >
            This feature helps senders and travelers coordinate urgent
            deliveries effectively:
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#666", lineHeight: "1.8", fontSize: "1rem" }}
          >
            <ul style={{ paddingLeft: "20px", marginBottom: "0" }}>
              <li>
                <strong>For Senders:</strong> Search for travelers open to
                courier requests during your required timeline. Coordinate
                directly after sending a courier request.
              </li>
              <li>
                <strong>For Travelers:</strong> If you are open to carrying
                items, submit your flight details at the home page and check the
                "Open to Courier" option. This adds you to the search database
                and people can send you courier request.
              </li>
              <li>
                Build meaningful connections while helping others with urgent
                needs.
              </li>
            </ul>
          </Typography>
        </Box>
      )}

      {loading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}

      {!loading && searched && couriers.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Available Travelers Open For Courier Request
          </Typography>
          <Grid container spacing={2}>
            {couriers.map((courier) => (
              <Grid item xs={12} sm={6} key={courier.id}>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "16px",
                        color: "#2E3B55",
                        textAlign: "center",
                      }}
                    >
                      {courier.fullName}
                    </Typography>

                    {courier.segments.map((segment, index) => (
                      <Box
                        key={index}
                        style={{
                          padding: "16px",
                          backgroundColor: "#f5faff",
                          border: "1px solid #dce6f2",
                          borderRadius: "8px",
                          marginBottom: "12px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <FlightTakeoffIcon
                              style={{ color: "#3f51b5", marginRight: "8px" }}
                            />
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: "600",
                                color: "#3f51b5",
                              }}
                            >
                              {segment.departure}
                            </Typography>
                          </Box>
                          <ArrowForwardIcon
                            style={{ margin: "0 8px", color: "#444" }}
                          />
                          <Box display="flex" alignItems="center">
                            <FlightLandIcon
                              style={{ color: "#2e7d32", marginRight: "8px" }}
                            />
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: "600",
                                color: "#2e7d32",
                              }}
                            >
                              {segment.arrival}
                            </Typography>
                          </Box>
                        </Box>

                        <Box mt={2}>
                          <Box display="flex" alignItems="center">
                            <EventIcon
                              style={{
                                color: "#ffa000",
                                marginRight: "8px",
                              }}
                            />
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              Departure: {segment.departureDate}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center" mt={1}>
                            <EventIcon
                              style={{
                                color: "#ffa000",
                                marginRight: "8px",
                              }}
                            />
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              Arrival: {segment.arrivalDate}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </CardContent>
                  <CardActions
                    style={{
                      padding: "12px",
                      justifyContent: "center",
                      borderTop: "1px solid #e0e0e0",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSendCourierRequest(courier)}
                      style={{
                        fontWeight: "bold",
                        letterSpacing: "0.5px",
                        borderRadius: "8px",
                        padding: "8px 20px",
                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      SEND COURIER REQUEST
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {!loading && searched && couriers.length === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            backgroundColor: "#ffebee", // Light red background
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            marginTop: "16px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#d32f2f", // Red text color for emphasis
              fontWeight: "bold",
              marginBottom: "8px",
            }}
          >
            No Couriers Found
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#555", // Subtle gray for secondary text
            }}
          >
            Please try selecting a different month.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchCouriers;
