const flightNumberPrefixes = {
    "Qatar Airways": "QR",
    "Air India": "AI",
    "Korean Air": "KE",
    "Turkish Airlines": "TK",
    "Thai Airways": "TG",
    "Etihad Airways": "EY",
    "China Southern Airlines": "CZ",
    "Emirates": "EK",
    "American Airlines": "AA",
    "Delta Air Lines": "DL",
    "United Airlines": "UA",
    "Southwest Airlines": "WN",
    "Alaska Airlines": "AS",
    "JetBlue Airways": "B6",
    "Spirit Airlines": "NK",
    "Frontier Airlines": "F9",
  };

  export default flightNumberPrefixes;